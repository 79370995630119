@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

.news-card {
  padding: 0px 0px;

  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  margin-bottom: 1.5rem;
  transition: border 0.5s ease-out;

  .news-hdr {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    transition: background-color 0.4s ease-out;
    padding: 10px 15px;
  }

  .news-content {
    padding: 10px 15px;
  }

  h2 {
    flex: 1;
    font-size: 1rem;
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 700;
  }

  .news-date {
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .news-status {
    font-size: 14px;
    display: flex;
    align-items: center;
    .status {
      margin-left: 1rem;
      padding: 0;
      width: 1rem;
      height: 1rem;
      box-sizing: border-box;
      border-radius: 50%;
      opacity: 0.8;

      &.status-new {
        background: red;
      }

      &.status-old {
        background: #edefef;
      }
    }
  }
}
