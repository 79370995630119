@import "icons_linear_variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?j6zmx9');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?j6zmx9#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?j6zmx9') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?j6zmx9') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?j6zmx9##{$icomoon-font-family}') format('svg');
   
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-linear-"], [class*=" icon-linear-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-linear-cart {
  &:before {
    content: $icon-linear-cart; 
  }
}
.icon-linear-bubbles {
  &:before {
    content: $icon-linear-bubbles; 
  }
}
