@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "icons_functional_variables";
@import "icons_linear_variables";
@import "icons_functional_aok_variables";

button:focus {
  outline: none;
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
  .btn-responsive {
    width: 100%;
  }
}

.btn.disabled,
.btn:disabled {
  opacity: 0.55;
}

.btn {
  min-width: 245px;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  height: 48px;
  text-decoration: none;
}

.btn-fitcontent {
  min-width: fit-content !important;
}

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/

.btn-aok-primary {
  @include button-variant(#6b6b6b, #6b6b6b, #5e5e5e, #5e5e5e, #525252);
}

.btn-primary_default {
  color: #fff;
  background-color: #6b6b6b;
  border: 1px solid transparent;
  &:hover {
    color: #fff;
    background-color: #5e5e5e;
  }
  &:disabled {
    color: #fff;
    background-color: #b3b4b3;
  }
  &:focus {
    color: #fff;
    background-color: #5e5e5e;
    box-shadow: inset 0 0 0 2px #1f1f1f;
  }
  &:active {
    color: #fff;
    background-color: #525252;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.2);
  }
}

.btn-primary_lightgreen {
  border: 0;
  color: #004730;
  background: linear-gradient(90deg, transparent 50%, #91f54a 0);
  background-position: 100% 100%;
  background-size: 200% 100%;
  transition: all 0.35s ease-in-out;
  box-shadow: inset 0px 0px 0px 2px #91f54a !important;
  @include hover() {
    color: #004730 !important;
    &:not(:disabled) {
      background-position: 0 100%;
    }
  }
}

.btn-secondary_default {
  border: 0;
  color: #004730;
  background: linear-gradient(90deg, #dfe3e6 50%, #fff0 0);
  background-position: 100% 100%;
  background-size: 200% 100%;
  transition: all 0.35s ease-in-out;
  box-shadow: inset 0px 0px 0px 2px #afb6bc !important;
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    color: #004730 !important;
    background-position: 0 100%;
  }
}

.btn-primary_orange {
  color: #fff;
  background-color: #ff9900;
  border: 1px solid transparent;
  &:hover {
    color: #fff;
    background-color: #f29100;
  }
  &:focus {
    color: #fff;
    background-color: #f29100;
    box-shadow: inset 0 0 0 2px #b26b00;
  }
  &:active {
    color: #fff;
    background-color: #e68a00;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.2);
  }
}

.btn-cart-mobile {
  min-width: auto;
  position: relative;
  padding: 0;
  margin: 0 20px;
  display: inline;
  &:hover {
    color: $primary;
  }
  i {
    font-size: 1.3333333333333333rem;
  }
  .badge {
    position: relative;
    left: -12px;
    top: -12px;
  }
}

.btn-cart {
  min-width: auto;
  // padding-left: 51px;
  // position: relative;
  i {
    position: relative;
    top: 1px;
    margin-right: 10px;
    font-size: 1.1666666666666667rem;
  }
  .badge {
    margin-left: 10px;
    font-size: 13px;
  }
}

.btn-more {
  padding-right: 50px;
  position: relative;
  &:after {
    position: absolute;
    right: 40px;
    display: inline-block;
    font-family: "aok-icons-functional-2023";
    text-rendering: auto;
    vertical-align: middle;
  }
}

.btn-back {
  position: relative;
  color: #293033 !important;
  min-width: auto;
  padding: 12px 24px;


  &:after {
    position: absolute;
    margin-left: .5rem;
    display: inline-block;
    font-family: "aok-icons-functional-2023";
    text-rendering: auto;
    vertical-align: middle;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, opacity, box-shadow, transform,
      filter, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.25s;
    transform-origin: center;
  }
  &:hover,
  :focus {
    text-decoration: none;

    &:after {
      transform: matrix(1.25, 0, 0, 1.25, 0, 0);
    }
  }
}

.btn-more {
  &:after {
    content: $aok-icon-functional-Arrow-Rounded-Right;
  }
}

.btn-back {
  &:after {
    content: $aok-icon-functional-Arrow-Rounded-Up;
  }
}

@include media-breakpoint-down(sm) {
  .btn-flex {
    min-width: inherit;
    width: 100%;
  }
}

.btn-delete {
  min-width: auto;
}

.btn-scrollToTop {
  position: fixed;
  min-width: 25px;
  min-height: 25px;
  right: 16px;
  bottom: 88px;
  width: 32px;
  height: 32px;
  padding: 4px;
  z-index: 1020;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s linear;

  &.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s linear;
  }
}
