$icomoon-font-family: "aok-icons-functional";
$icomoon-font-path: "../assets/fonts";

$icon-home7: "\e906";
$icon-pencil4: "\e911";
$icon-cart: "\e9fe";
$icon-envelop: "\ea30";
$icon-location: "\ea38";
$icon-clock4: "\ea50";
$icon-bell21: "\ea59";
$icon-calendar2: "\ea60";
$icon-cog4: "\eb63";
$icon-menu7: "\ec71";
$icon-upload2: "\ec7f";
$icon-warning: "\ed4f";
$icon-plus-circle: "\ed5f";
$icon-cancel-circle2: "\ed66";
$icon-checkmark3: "\ed71";
$icon-arrow-up4: "\edba";
$icon-arrow-right4: "\edbe";
$icon-arrow-down4: "\edc2";
$icon-arrow-left4: "\edc6";
$icon-circle-right4: "\edf6";
$icon-circle-left4: "\edfa";
$icon-checkmark-circle: "\ee76";
$icon-new-tab: "\eec9";
