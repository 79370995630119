@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "typography";

#carouselIndicators {
  margin: 0px auto;
  margin-top: 40px;

  @include media-breakpoint-up(md) {
    margin: 0px auto;
  }

  .carousel-outer {
    position: relative;
    height: auto;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      height: 373px;
    }

    .carousel-inner {
      height: 100%;
      display: flex;

      .carousel-item {
      }
    }
  }

  .carousel-indicators {
    margin: 10px 0 0;
    overflow: auto;
    position: relative;
    justify-content: flex-start;
    white-space: nowrap;
    width: 100%;

    li {
      background-color: transparent;
      -webkit-border-radius: 0;
      border-radius: 0;
      display: inline-block;
      height: 51px;
      width: calc(
        ((100% - (20px * (12 - 1))) / 12 * 2 + ((2 - 1) * (20px * 0.998))) * 1
      );
      overflow: hidden;

      &.active {
        &:before {
          padding-top: 0%;
          content: "";
          display: block;
        }
        &:after {
          position: relative;
          bottom: 38px;
          height: 3px;
          background: $primary;
          display: inline-block;
          opacity: 1;
          transition: opacity 250ms ease;
          content: "";
          width: 100%;
        }
      }
      &:active {
        img {
          opacity: 1;
        }
      }

      &:hover {
        img {
          opacity: 1;
        }
      }

      img {
        display: block;
        opacity: 1;
        height: 72px;
        width: 100%;
      }
    }
  }
}

.premium-shop {
  .premium-score-item,
  .tip-text {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    .premium-score-item,
    .tip-text {
      width: calc(
        ((100% - (20px * (12 - 1))) / 12 * 8 + ((8 - 1) * (20px * 0.998))) * 1
      );
    }
  }

  .premium-score-item {
    @extend .h5;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
}
