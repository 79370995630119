@import "icons_aok_variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?75elj8');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?75elj8#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?75elj8') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?75elj8') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?75elj8##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="aok-icon-"], [class*=" aok-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aok-icon-Abrechnung_rgb {
  &:before {
    content: $aok-icon-Abrechnung_rgb; 
  }
}
.aok-icon-Abschlussarbeiten_rgb {
  &:before {
    content: $aok-icon-Abschlussarbeiten_rgb; 
  }
}
.aok-icon-Allergiebettwaesche_rgb {
  &:before {
    content: $aok-icon-Allergiebettwaesche_rgb; 
  }
}
.aok-icon-AOK_Bonusprogramm_rgb {
  &:before {
    content: $aok-icon-AOK_Bonusprogramm_rgb; 
  }
}
.aok-icon-AOK_empfehlen_rgb {
  &:before {
    content: $aok-icon-AOK_empfehlen_rgb; 
  }
}
.aok-icon-AOK_Gesundheitskonto_rgb {
  &:before {
    content: $aok-icon-AOK_Gesundheitskonto_rgb; 
  }
}
.aok-icon-AOK_Praemienprogramm_rgb {
  &:before {
    content: $aok-icon-AOK_Praemienprogramm_rgb; 
  }
}
.aok-icon-Apotheke_rgb {
  &:before {
    content: $aok-icon-Apotheke_rgb; 
  }
}
.aok-icon-Apps_rgb {
  &:before {
    content: $aok-icon-Apps_rgb; 
  }
}
.aok-icon-Arbeitslosenversicherung-_rgb {
  &:before {
    content: $aok-icon-Arbeitslosenversicherung-_rgb; 
  }
}
.aok-icon-Arzt_rgb {
  &:before {
    content: $aok-icon-Arzt_rgb; 
  }
}
.aok-icon-Arztsuche_rgb {
  &:before {
    content: $aok-icon-Arztsuche_rgb; 
  }
}
.aok-icon-Assistenzhund_rgb {
  &:before {
    content: $aok-icon-Assistenzhund_rgb; 
  }
}
.aok-icon-Aufzug_rgb {
  &:before {
    content: $aok-icon-Aufzug_rgb; 
  }
}
.aok-icon-Ausbildungsberufe_rgb {
  &:before {
    content: $aok-icon-Ausbildungsberufe_rgb; 
  }
}
.aok-icon-Auto_rgb {
  &:before {
    content: $aok-icon-Auto_rgb; 
  }
}
.aok-icon-Baby_Bonus_rgb {
  &:before {
    content: $aok-icon-Baby_Bonus_rgb; 
  }
}
.aok-icon-Baby_rgb {
  &:before {
    content: $aok-icon-Baby_rgb; 
  }
}
.aok-icon-Barrierefreiheit_rgb {
  &:before {
    content: $aok-icon-Barrierefreiheit_rgb; 
  }
}
.aok-icon-Batterie_laden_rgb {
  &:before {
    content: $aok-icon-Batterie_laden_rgb; 
  }
}
.aok-icon-Behandlung_beantragen_rgb {
  &:before {
    content: $aok-icon-Behandlung_beantragen_rgb; 
  }
}
.aok-icon-Behandlung_beantragen_Zahn_rgb {
  &:before {
    content: $aok-icon-Behandlung_beantragen_Zahn_rgb; 
  }
}
.aok-icon-Behandlung_im_Ausland_rgb {
  &:before {
    content: $aok-icon-Behandlung_im_Ausland_rgb; 
  }
}
.aok-icon-Behandlung_in_Europa_rgb {
  &:before {
    content: $aok-icon-Behandlung_in_Europa_rgb; 
  }
}
.aok-icon-Behandlung_planen_rgb {
  &:before {
    content: $aok-icon-Behandlung_planen_rgb; 
  }
}
.aok-icon-Behandlung_planen_Zahn_rgb {
  &:before {
    content: $aok-icon-Behandlung_planen_Zahn_rgb; 
  }
}
.aok-icon-Behandlung_rgb {
  &:before {
    content: $aok-icon-Behandlung_rgb; 
  }
}
.aok-icon-Beitraege_rgb {
  &:before {
    content: $aok-icon-Beitraege_rgb; 
  }
}
.aok-icon-Benutzerhandbuch_rgb {
  &:before {
    content: $aok-icon-Benutzerhandbuch_rgb; 
  }
}
.aok-icon-Benutzerkonto_rgb {
  &:before {
    content: $aok-icon-Benutzerkonto_rgb; 
  }
}
.aok-icon-Berufserfahrene_rgb {
  &:before {
    content: $aok-icon-Berufserfahrene_rgb; 
  }
}
.aok-icon-Besprechungsraum_rgb {
  &:before {
    content: $aok-icon-Besprechungsraum_rgb; 
  }
}
.aok-icon-Besteck_rgb {
  &:before {
    content: $aok-icon-Besteck_rgb; 
  }
}
.aok-icon-Bewerbung_rgb {
  &:before {
    content: $aok-icon-Bewerbung_rgb; 
  }
}
.aok-icon-Beziehungen_rgb {
  &:before {
    content: $aok-icon-Beziehungen_rgb; 
  }
}
.aok-icon-Blitz_rgb {
  &:before {
    content: $aok-icon-Blitz_rgb; 
  }
}
.aok-icon-Briefumschlag_offen_rgb {
  &:before {
    content: $aok-icon-Briefumschlag_offen_rgb; 
  }
}
.aok-icon-Briefumschlag_rgb {
  &:before {
    content: $aok-icon-Briefumschlag_rgb; 
  }
}
.aok-icon-Brille_rgb {
  &:before {
    content: $aok-icon-Brille_rgb; 
  }
}
.aok-icon-Brot_rgb {
  &:before {
    content: $aok-icon-Brot_rgb; 
  }
}
.aok-icon-Cafeteria_rgb {
  &:before {
    content: $aok-icon-Cafeteria_rgb; 
  }
}
.aok-icon-Charity_rgb .path1 {
  &:before {
    content: $aok-icon-Charity_rgb-path1;  
    color: rgb(0, 0, 0);
  }
}
.aok-icon-Charity_rgb .path2 {
  &:before {
    content: $aok-icon-Charity_rgb-path2;  
    margin-left: -1em;  
    color: rgb(29, 29, 27);
  }
}
.aok-icon-Chat_online_rgb {
  &:before {
    content: $aok-icon-Chat_online_rgb; 
  }
}
.aok-icon-Chatbot_rgb {
  &:before {
    content: $aok-icon-Chatbot_rgb; 
  }
}
.aok-icon-Check_rgb {
  &:before {
    content: $aok-icon-Check_rgb; 
  }
}
.aok-icon-Cloud_rgb {
  &:before {
    content: $aok-icon-Cloud_rgb; 
  }
}
.aok-icon-Co-Browsing_rgb {
  &:before {
    content: $aok-icon-Co-Browsing_rgb; 
  }
}
.aok-icon-Code_rgb {
  &:before {
    content: $aok-icon-Code_rgb; 
  }
}
.aok-icon-Computer_rgb {
  &:before {
    content: $aok-icon-Computer_rgb; 
  }
}
.aok-icon-Cookies_rgb {
  &:before {
    content: $aok-icon-Cookies_rgb; 
  }
}
.aok-icon-Datei_rgb {
  &:before {
    content: $aok-icon-Datei_rgb; 
  }
}
.aok-icon-Dateien_rgb {
  &:before {
    content: $aok-icon-Dateien_rgb; 
  }
}
.aok-icon-Datenaustausch_rgb {
  &:before {
    content: $aok-icon-Datenaustausch_rgb; 
  }
}
.aok-icon-Demenz_rgb {
  &:before {
    content: $aok-icon-Demenz_rgb; 
  }
}
.aok-icon-Devices_rgb {
  &:before {
    content: $aok-icon-Devices_rgb; 
  }
}
.aok-icon-Diagramm_rgb {
  &:before {
    content: $aok-icon-Diagramm_rgb; 
  }
}
.aok-icon-Diamant_rgb {
  &:before {
    content: $aok-icon-Diamant_rgb; 
  }
}
.aok-icon-Digitale_Gesundheitsanwendungen_rgb {
  &:before {
    content: $aok-icon-Digitale_Gesundheitsanwendungen_rgb; 
  }
}
.aok-icon-Direkteinstieg_rgb {
  &:before {
    content: $aok-icon-Direkteinstieg_rgb; 
  }
}
.aok-icon-Dokument_rgb {
  &:before {
    content: $aok-icon-Dokument_rgb; 
  }
}
.aok-icon-Download_Folder_rgb {
  &:before {
    content: $aok-icon-Download_Folder_rgb; 
  }
}
.aok-icon-Download_rgb {
  &:before {
    content: $aok-icon-Download_rgb; 
  }
}
.aok-icon-Duales_Studium_rgb {
  &:before {
    content: $aok-icon-Duales_Studium_rgb; 
  }
}
.aok-icon-Elektronische_Gesundheitskarte_rgb {
  &:before {
    content: $aok-icon-Elektronische_Gesundheitskarte_rgb; 
  }
}
.aok-icon-Elektronische_Patientenquittung_rgb {
  &:before {
    content: $aok-icon-Elektronische_Patientenquittung_rgb; 
  }
}
.aok-icon-Entspannung_rgb {
  &:before {
    content: $aok-icon-Entspannung_rgb; 
  }
}
.aok-icon-Ernaehrung_rgb {
  &:before {
    content: $aok-icon-Ernaehrung_rgb; 
  }
}
.aok-icon-Erstattung_rgb {
  &:before {
    content: $aok-icon-Erstattung_rgb; 
  }
}
.aok-icon-Fahrrad_rgb {
  &:before {
    content: $aok-icon-Fahrrad_rgb; 
  }
}
.aok-icon-Fahrtkosten_rgb {
  &:before {
    content: $aok-icon-Fahrtkosten_rgb; 
  }
}
.aok-icon-Familie_rgb {
  &:before {
    content: $aok-icon-Familie_rgb; 
  }
}
.aok-icon-Fernglas_rgb {
  &:before {
    content: $aok-icon-Fernglas_rgb; 
  }
}
.aok-icon-Feuer_rgb {
  &:before {
    content: $aok-icon-Feuer_rgb; 
  }
}
.aok-icon-Fieberthermometer_rgb {
  &:before {
    content: $aok-icon-Fieberthermometer_rgb; 
  }
}
.aok-icon-Firmenkunden_rgb {
  &:before {
    content: $aok-icon-Firmenkunden_rgb; 
  }
}
.aok-icon-Flagge_rgb {
  &:before {
    content: $aok-icon-Flagge_rgb; 
  }
}
.aok-icon-Folder_rgb {
  &:before {
    content: $aok-icon-Folder_rgb; 
  }
}
.aok-icon-Formulare_rgb {
  &:before {
    content: $aok-icon-Formulare_rgb; 
  }
}
.aok-icon-Freiwillige_Versicherung_rgb {
  &:before {
    content: $aok-icon-Freiwillige_Versicherung_rgb; 
  }
}
.aok-icon-Frueherkennung_rgb {
  &:before {
    content: $aok-icon-Frueherkennung_rgb; 
  }
}
.aok-icon-Garderobe_rgb {
  &:before {
    content: $aok-icon-Garderobe_rgb; 
  }
}
.aok-icon-Gebaerdensprache_rgb {
  &:before {
    content: $aok-icon-Gebaerdensprache_rgb; 
  }
}
.aok-icon-Geburtsvorbereitungskurs_rgb {
  &:before {
    content: $aok-icon-Geburtsvorbereitungskurs_rgb; 
  }
}
.aok-icon-Gedanken_rgb {
  &:before {
    content: $aok-icon-Gedanken_rgb; 
  }
}
.aok-icon-Geldbeutel_rgb {
  &:before {
    content: $aok-icon-Geldbeutel_rgb; 
  }
}
.aok-icon-Genehmigung_abwarten_rgb {
  &:before {
    content: $aok-icon-Genehmigung_abwarten_rgb; 
  }
}
.aok-icon-Geschaeftsstelle_online_rgb {
  &:before {
    content: $aok-icon-Geschaeftsstelle_online_rgb; 
  }
}
.aok-icon-Geschaeftsstelle_rgb {
  &:before {
    content: $aok-icon-Geschaeftsstelle_rgb; 
  }
}
.aok-icon-Geschenk_rgb {
  &:before {
    content: $aok-icon-Geschenk_rgb; 
  }
}
.aok-icon-Gesetz_rgb {
  &:before {
    content: $aok-icon-Gesetz_rgb; 
  }
}
.aok-icon-Gesundheit_rgb {
  &:before {
    content: $aok-icon-Gesundheit_rgb; 
  }
}
.aok-icon-Gesundheitscheck_rgb {
  &:before {
    content: $aok-icon-Gesundheitscheck_rgb; 
  }
}
.aok-icon-Gesundheitskurse_rgb {
  &:before {
    content: $aok-icon-Gesundheitskurse_rgb; 
  }
}
.aok-icon-Gesunheitspartner_rgb {
  &:before {
    content: $aok-icon-Gesunheitspartner_rgb; 
  }
}
.aok-icon-Gluehbirne_rgb {
  &:before {
    content: $aok-icon-Gluehbirne_rgb; 
  }
}
.aok-icon-Haeusliche_Krankenpflege_rgb {
  &:before {
    content: $aok-icon-Haeusliche_Krankenpflege_rgb; 
  }
}
.aok-icon-Hand_Daumen_hoch_rgb {
  &:before {
    content: $aok-icon-Hand_Daumen_hoch_rgb; 
  }
}
.aok-icon-Hand_Daumen_neutral_rgb {
  &:before {
    content: $aok-icon-Hand_Daumen_neutral_rgb; 
  }
}
.aok-icon-Hand_Daumen_runter_rgb {
  &:before {
    content: $aok-icon-Hand_Daumen_runter_rgb; 
  }
}
.aok-icon-Hand_stopp_rgb {
  &:before {
    content: $aok-icon-Hand_stopp_rgb; 
  }
}
.aok-icon-Hand_zeigen_rgb {
  &:before {
    content: $aok-icon-Hand_zeigen_rgb; 
  }
}
.aok-icon-Happy_rgb {
  &:before {
    content: $aok-icon-Happy_rgb; 
  }
}
.aok-icon-Hausarztprogramm_rgb {
  &:before {
    content: $aok-icon-Hausarztprogramm_rgb; 
  }
}
.aok-icon-Haushaltshilfe_rgb {
  &:before {
    content: $aok-icon-Haushaltshilfe_rgb; 
  }
}
.aok-icon-Hautirritationen_rgb {
  &:before {
    content: $aok-icon-Hautirritationen_rgb; 
  }
}
.aok-icon-Hebamme_rgb {
  &:before {
    content: $aok-icon-Hebamme_rgb; 
  }
}
.aok-icon-Herz_rgb {
  &:before {
    content: $aok-icon-Herz_rgb; 
  }
}
.aok-icon-Hoerbeeintraechtigung_rgb {
  &:before {
    content: $aok-icon-Hoerbeeintraechtigung_rgb; 
  }
}
.aok-icon-Hygiene_Armbeuge_rgb {
  &:before {
    content: $aok-icon-Hygiene_Armbeuge_rgb; 
  }
}
.aok-icon-Hygiene_Desinfektion_rgb {
  &:before {
    content: $aok-icon-Hygiene_Desinfektion_rgb; 
  }
}
.aok-icon-Hygiene_Maske_rgb {
  &:before {
    content: $aok-icon-Hygiene_Maske_rgb; 
  }
}
.aok-icon-Impfung_rgb {
  &:before {
    content: $aok-icon-Impfung_rgb; 
  }
}
.aok-icon-Initiativbewerbung_rgb {
  &:before {
    content: $aok-icon-Initiativbewerbung_rgb; 
  }
}
.aok-icon-Integrierte_Versorgung_rgb {
  &:before {
    content: $aok-icon-Integrierte_Versorgung_rgb; 
  }
}
.aok-icon-Jolinchen_rgb {
  &:before {
    content: $aok-icon-Jolinchen_rgb; 
  }
}
.aok-icon-Jugendliche_rgb {
  &:before {
    content: $aok-icon-Jugendliche_rgb; 
  }
}
.aok-icon-Kaefer_rgb {
  &:before {
    content: $aok-icon-Kaefer_rgb; 
  }
}
.aok-icon-Kalender_Check_rgb {
  &:before {
    content: $aok-icon-Kalender_Check_rgb; 
  }
}
.aok-icon-Kalender_mit_Datum_rgb {
  &:before {
    content: $aok-icon-Kalender_mit_Datum_rgb; 
  }
}
.aok-icon-Kalender_mit_Pfeil_rgb {
  &:before {
    content: $aok-icon-Kalender_mit_Pfeil_rgb; 
  }
}
.aok-icon-Kalender_rgb {
  &:before {
    content: $aok-icon-Kalender_rgb; 
  }
}
.aok-icon-Karte_rgb {
  &:before {
    content: $aok-icon-Karte_rgb; 
  }
}
.aok-icon-Kind_rgb {
  &:before {
    content: $aok-icon-Kind_rgb; 
  }
}
.aok-icon-Kinderkrankengeld_rgb {
  &:before {
    content: $aok-icon-Kinderkrankengeld_rgb; 
  }
}
.aok-icon-Knochen_rgb {
  &:before {
    content: $aok-icon-Knochen_rgb; 
  }
}
.aok-icon-Kochmtze_rgb {
  &:before {
    content: $aok-icon-Kochmtze_rgb; 
  }
}
.aok-icon-Kompass_rgb {
  &:before {
    content: $aok-icon-Kompass_rgb; 
  }
}
.aok-icon-Kopfhoerer_rgb {
  &:before {
    content: $aok-icon-Kopfhoerer_rgb; 
  }
}
.aok-icon-Krankengeld_rgb {
  &:before {
    content: $aok-icon-Krankengeld_rgb; 
  }
}
.aok-icon-Krankenhaus_rgb {
  &:before {
    content: $aok-icon-Krankenhaus_rgb; 
  }
}
.aok-icon-Krankenhausbehandlung_rgb {
  &:before {
    content: $aok-icon-Krankenhausbehandlung_rgb; 
  }
}
.aok-icon-Krankentransport_rgb {
  &:before {
    content: $aok-icon-Krankentransport_rgb; 
  }
}
.aok-icon-Krankenversicherung_rgb {
  &:before {
    content: $aok-icon-Krankenversicherung_rgb; 
  }
}
.aok-icon-Krankmeldung_rgb {
  &:before {
    content: $aok-icon-Krankmeldung_rgb; 
  }
}
.aok-icon-Kuendigungsformular_rgb {
  &:before {
    content: $aok-icon-Kuendigungsformular_rgb; 
  }
}
.aok-icon-Kundenzufriedenheit_rgb {
  &:before {
    content: $aok-icon-Kundenzufriedenheit_rgb; 
  }
}
.aok-icon-Kur_rgb {
  &:before {
    content: $aok-icon-Kur_rgb; 
  }
}
.aok-icon-Lautstaerke_rgb {
  &:before {
    content: $aok-icon-Lautstaerke_rgb; 
  }
}
.aok-icon-Leichte_Sprache_rgb {
  &:before {
    content: $aok-icon-Leichte_Sprache_rgb; 
  }
}
.aok-icon-Lesezeichen_rgb {
  &:before {
    content: $aok-icon-Lesezeichen_rgb; 
  }
}
.aok-icon-Lippenstift_Beauty_rgb {
  &:before {
    content: $aok-icon-Lippenstift_Beauty_rgb; 
  }
}
.aok-icon-Location_rgb {
  &:before {
    content: $aok-icon-Location_rgb; 
  }
}
.aok-icon-Luftballon_rgb {
  &:before {
    content: $aok-icon-Luftballon_rgb; 
  }
}
.aok-icon-Lunge_Atmungssystem_rgb {
  &:before {
    content: $aok-icon-Lunge_Atmungssystem_rgb; 
  }
}
.aok-icon-Magazin_rgb {
  &:before {
    content: $aok-icon-Magazin_rgb; 
  }
}
.aok-icon-Medaille_rgb {
  &:before {
    content: $aok-icon-Medaille_rgb; 
  }
}
.aok-icon-Mediale_Angebote_rgb {
  &:before {
    content: $aok-icon-Mediale_Angebote_rgb; 
  }
}
.aok-icon-Medikamente_rgb {
  &:before {
    content: $aok-icon-Medikamente_rgb; 
  }
}
.aok-icon-Medizin-und-Versorgung_rgb {
  &:before {
    content: $aok-icon-Medizin-und-Versorgung_rgb; 
  }
}
.aok-icon-Megafon_rgb {
  &:before {
    content: $aok-icon-Megafon_rgb; 
  }
}
.aok-icon-Mikrofon_rgb {
  &:before {
    content: $aok-icon-Mikrofon_rgb; 
  }
}
.aok-icon-Mitglied-werden_rgb {
  &:before {
    content: $aok-icon-Mitglied-werden_rgb; 
  }
}
.aok-icon-Mond_rgb {
  &:before {
    content: $aok-icon-Mond_rgb; 
  }
}
.aok-icon-Mund_rgb {
  &:before {
    content: $aok-icon-Mund_rgb; 
  }
}
.aok-icon-Mutterschaftsgeld_rgb {
  &:before {
    content: $aok-icon-Mutterschaftsgeld_rgb; 
  }
}
.aok-icon-Nachhaltigkeit_rgb {
  &:before {
    content: $aok-icon-Nachhaltigkeit_rgb; 
  }
}
.aok-icon-Netzwerk_Zusammenhaenge_rgb {
  &:before {
    content: $aok-icon-Netzwerk_Zusammenhaenge_rgb; 
  }
}
.aok-icon-Newsletter_rgb {
  &:before {
    content: $aok-icon-Newsletter_rgb; 
  }
}
.aok-icon-Nummer-10_rgb {
  &:before {
    content: $aok-icon-Nummer-10_rgb; 
  }
}
.aok-icon-Nummer_0_rgb {
  &:before {
    content: $aok-icon-Nummer_0_rgb; 
  }
}
.aok-icon-Nummer_1_rgb {
  &:before {
    content: $aok-icon-Nummer_1_rgb; 
  }
}
.aok-icon-Nummer_2_rgb {
  &:before {
    content: $aok-icon-Nummer_2_rgb; 
  }
}
.aok-icon-Nummer_3_rgb {
  &:before {
    content: $aok-icon-Nummer_3_rgb; 
  }
}
.aok-icon-Nummer_4_rgb {
  &:before {
    content: $aok-icon-Nummer_4_rgb; 
  }
}
.aok-icon-Nummer_5_rgb {
  &:before {
    content: $aok-icon-Nummer_5_rgb; 
  }
}
.aok-icon-Nummer_6_rgb {
  &:before {
    content: $aok-icon-Nummer_6_rgb; 
  }
}
.aok-icon-Nummer_7_rgb {
  &:before {
    content: $aok-icon-Nummer_7_rgb; 
  }
}
.aok-icon-Nummer_8_rgb {
  &:before {
    content: $aok-icon-Nummer_8_rgb; 
  }
}
.aok-icon-Nummer_9_rgb {
  &:before {
    content: $aok-icon-Nummer_9_rgb; 
  }
}
.aok-icon-Online_Kurse_rgb {
  &:before {
    content: $aok-icon-Online_Kurse_rgb; 
  }
}
.aok-icon-Osteopathie_rgb {
  &:before {
    content: $aok-icon-Osteopathie_rgb; 
  }
}
.aok-icon-Parkplatz_rgb {
  &:before {
    content: $aok-icon-Parkplatz_rgb; 
  }
}
.aok-icon-Persoenliche_Beratung_rgb {
  &:before {
    content: $aok-icon-Persoenliche_Beratung_rgb; 
  }
}
.aok-icon-Personenwaage_rgb {
  &:before {
    content: $aok-icon-Personenwaage_rgb; 
  }
}
.aok-icon-Pfeil_links_oben_rgb {
  &:before {
    content: $aok-icon-Pfeil_links_oben_rgb; 
  }
}
.aok-icon-Pfeil_links_rgb {
  &:before {
    content: $aok-icon-Pfeil_links_rgb; 
  }
}
.aok-icon-Pfeil_links_unten_rgb {
  &:before {
    content: $aok-icon-Pfeil_links_unten_rgb; 
  }
}
.aok-icon-Pfeil_oben_rgb {
  &:before {
    content: $aok-icon-Pfeil_oben_rgb; 
  }
}
.aok-icon-Pfeil_rechts_oben_rgb {
  &:before {
    content: $aok-icon-Pfeil_rechts_oben_rgb; 
  }
}
.aok-icon-Pfeil_rechts_rgb {
  &:before {
    content: $aok-icon-Pfeil_rechts_rgb; 
  }
}
.aok-icon-Pfeil_rechts_unten_rgb {
  &:before {
    content: $aok-icon-Pfeil_rechts_unten_rgb; 
  }
}
.aok-icon-Pfeil_unten_rgb {
  &:before {
    content: $aok-icon-Pfeil_unten_rgb; 
  }
}
.aok-icon-Pflaster_rgb {
  &:before {
    content: $aok-icon-Pflaster_rgb; 
  }
}
.aok-icon-Pflege_rgb {
  &:before {
    content: $aok-icon-Pflege_rgb; 
  }
}
.aok-icon-Pflegeversicherung_rgb {
  &:before {
    content: $aok-icon-Pflegeversicherung_rgb; 
  }
}
.aok-icon-Physiotherapie_rgb {
  &:before {
    content: $aok-icon-Physiotherapie_rgb; 
  }
}
.aok-icon-Plakat_Aussenwerbung_rgb {
  &:before {
    content: $aok-icon-Plakat_Aussenwerbung_rgb; 
  }
}
.aok-icon-Plus_rgb {
  &:before {
    content: $aok-icon-Plus_rgb; 
  }
}
.aok-icon-Podcast_rgb {
  &:before {
    content: $aok-icon-Podcast_rgb; 
  }
}
.aok-icon-Pokal_rgb {
  &:before {
    content: $aok-icon-Pokal_rgb; 
  }
}
.aok-icon-Professionelle_Zahnreinigung_rgb {
  &:before {
    content: $aok-icon-Professionelle_Zahnreinigung_rgb; 
  }
}
.aok-icon-Rakete_Fortschritt_rgb {
  &:before {
    content: $aok-icon-Rakete_Fortschritt_rgb; 
  }
}
.aok-icon-Rechte_als_Patient_rgb {
  &:before {
    content: $aok-icon-Rechte_als_Patient_rgb; 
  }
}
.aok-icon-Rechtsdatenbank_rgb {
  &:before {
    content: $aok-icon-Rechtsdatenbank_rgb; 
  }
}
.aok-icon-Recycling_rgb {
  &:before {
    content: $aok-icon-Recycling_rgb; 
  }
}
.aok-icon-Regenschirm_rgb {
  &:before {
    content: $aok-icon-Regenschirm_rgb; 
  }
}
.aok-icon-Reha_rgb {
  &:before {
    content: $aok-icon-Reha_rgb; 
  }
}
.aok-icon-Reise_rgb {
  &:before {
    content: $aok-icon-Reise_rgb; 
  }
}
.aok-icon-Rettungsring_Nothilfe_rgb {
  &:before {
    content: $aok-icon-Rettungsring_Nothilfe_rgb; 
  }
}
.aok-icon-Rollstuhl_rgb {
  &:before {
    content: $aok-icon-Rollstuhl_rgb; 
  }
}
.aok-icon-Schluessel_rgb {
  &:before {
    content: $aok-icon-Schluessel_rgb; 
  }
}
.aok-icon-Schueler_innen_rgb {
  &:before {
    content: $aok-icon-Schueler_innen_rgb; 
  }
}
.aok-icon-Schwangerschaft_rgb {
  &:before {
    content: $aok-icon-Schwangerschaft_rgb; 
  }
}
.aok-icon-Sehbeeintraechtigung_rgb {
  &:before {
    content: $aok-icon-Sehbeeintraechtigung_rgb; 
  }
}
.aok-icon-Selbsthilfefoerderung_rgb {
  &:before {
    content: $aok-icon-Selbsthilfefoerderung_rgb; 
  }
}
.aok-icon-Selfservice_rgb {
  &:before {
    content: $aok-icon-Selfservice_rgb; 
  }
}
.aok-icon-Seminar_online_rgb {
  &:before {
    content: $aok-icon-Seminar_online_rgb; 
  }
}
.aok-icon-Seminar_rgb {
  &:before {
    content: $aok-icon-Seminar_rgb; 
  }
}
.aok-icon-Smartphone_rgb {
  &:before {
    content: $aok-icon-Smartphone_rgb; 
  }
}
.aok-icon-Sonne_rgb {
  &:before {
    content: $aok-icon-Sonne_rgb; 
  }
}
.aok-icon-Sparschwein_rgb {
  &:before {
    content: $aok-icon-Sparschwein_rgb; 
  }
}
.aok-icon-Sport_rgb {
  &:before {
    content: $aok-icon-Sport_rgb; 
  }
}
.aok-icon-Sprechblase_mit_Ausrufezeichen_rgb {
  &:before {
    content: $aok-icon-Sprechblase_mit_Ausrufezeichen_rgb; 
  }
}
.aok-icon-Sprechblase_mit_Fragezeichen_rgb {
  &:before {
    content: $aok-icon-Sprechblase_mit_Fragezeichen_rgb; 
  }
}
.aok-icon-Sprechblase_mit_Punkten_rgb {
  &:before {
    content: $aok-icon-Sprechblase_mit_Punkten_rgb; 
  }
}
.aok-icon-Sprechblase_mit_Text_rgb {
  &:before {
    content: $aok-icon-Sprechblase_mit_Text_rgb; 
  }
}
.aok-icon-Sprechblase_rgb {
  &:before {
    content: $aok-icon-Sprechblase_rgb; 
  }
}
.aok-icon-Sprechblasen_rgb {
  &:before {
    content: $aok-icon-Sprechblasen_rgb; 
  }
}
.aok-icon-Stadt_rgb {
  &:before {
    content: $aok-icon-Stadt_rgb; 
  }
}
.aok-icon-Stellenangebote_rgb {
  &:before {
    content: $aok-icon-Stellenangebote_rgb; 
  }
}
.aok-icon-Stern_rgb {
  &:before {
    content: $aok-icon-Stern_rgb; 
  }
}
.aok-icon-Stoffwechsel_rgb {
  &:before {
    content: $aok-icon-Stoffwechsel_rgb; 
  }
}
.aok-icon-Stress_rgb {
  &:before {
    content: $aok-icon-Stress_rgb; 
  }
}
.aok-icon-Stressbewaeltigung_rgb {
  &:before {
    content: $aok-icon-Stressbewaeltigung_rgb; 
  }
}
.aok-icon-Studierende_rgb {
  &:before {
    content: $aok-icon-Studierende_rgb; 
  }
}
.aok-icon-Suche_nach_Leistungserbringern_rgb {
  &:before {
    content: $aok-icon-Suche_nach_Leistungserbringern_rgb; 
  }
}
.aok-icon-Suche_rgb {
  &:before {
    content: $aok-icon-Suche_rgb; 
  }
}
.aok-icon-Synapsen_rgb {
  &:before {
    content: $aok-icon-Synapsen_rgb; 
  }
}
.aok-icon-Tacho_rgb {
  &:before {
    content: $aok-icon-Tacho_rgb; 
  }
}
.aok-icon-Tarife_rgb {
  &:before {
    content: $aok-icon-Tarife_rgb; 
  }
}
.aok-icon-Telefon_mit_Pfeil_rgb {
  &:before {
    content: $aok-icon-Telefon_mit_Pfeil_rgb; 
  }
}
.aok-icon-Telefon_mit_Schallwellen_rgb {
  &:before {
    content: $aok-icon-Telefon_mit_Schallwellen_rgb; 
  }
}
.aok-icon-Telefon_mit_Sprechblase_rgb {
  &:before {
    content: $aok-icon-Telefon_mit_Sprechblase_rgb; 
  }
}
.aok-icon-Telefon_rgb {
  &:before {
    content: $aok-icon-Telefon_rgb; 
  }
}
.aok-icon-Trauben_rgb {
  &:before {
    content: $aok-icon-Trauben_rgb; 
  }
}
.aok-icon-Traurig_rgb {
  &:before {
    content: $aok-icon-Traurig_rgb; 
  }
}
.aok-icon-Treppenhaus_rgb {
  &:before {
    content: $aok-icon-Treppenhaus_rgb; 
  }
}
.aok-icon-Tropfen_rgb {
  &:before {
    content: $aok-icon-Tropfen_rgb; 
  }
}
.aok-icon-Uhr_rgb {
  &:before {
    content: $aok-icon-Uhr_rgb; 
  }
}
.aok-icon-Unfallversicherung_rgb {
  &:before {
    content: $aok-icon-Unfallversicherung_rgb; 
  }
}
.aok-icon-Upload_Folder_rgb {
  &:before {
    content: $aok-icon-Upload_Folder_rgb; 
  }
}
.aok-icon-Upload_rgb {
  &:before {
    content: $aok-icon-Upload_rgb; 
  }
}
.aok-icon-Verdauung_rgb {
  &:before {
    content: $aok-icon-Verdauung_rgb; 
  }
}
.aok-icon-Versicherung_in_Europa_rgb {
  &:before {
    content: $aok-icon-Versicherung_in_Europa_rgb; 
  }
}
.aok-icon-Versicherung_rgb {
  &:before {
    content: $aok-icon-Versicherung_rgb; 
  }
}
.aok-icon-Video_rgb {
  &:before {
    content: $aok-icon-Video_rgb; 
  }
}
.aok-icon-Vielfalt_rgb {
  &:before {
    content: $aok-icon-Vielfalt_rgb; 
  }
}
.aok-icon-Vorsorge_rgb {
  &:before {
    content: $aok-icon-Vorsorge_rgb; 
  }
}
.aok-icon-Wahltarife_rgb {
  &:before {
    content: $aok-icon-Wahltarife_rgb; 
  }
}
.aok-icon-Wartebereich_rgb {
  &:before {
    content: $aok-icon-Wartebereich_rgb; 
  }
}
.aok-icon-WC_behindertengerecht_rgb {
  &:before {
    content: $aok-icon-WC_behindertengerecht_rgb; 
  }
}
.aok-icon-WC_beide_rgb {
  &:before {
    content: $aok-icon-WC_beide_rgb; 
  }
}
.aok-icon-WC_Damen_rgb {
  &:before {
    content: $aok-icon-WC_Damen_rgb; 
  }
}
.aok-icon-WC_genderneutral_1_rgb {
  &:before {
    content: $aok-icon-WC_genderneutral_1_rgb; 
  }
}
.aok-icon-WC_genderneutral_2_rgb {
  &:before {
    content: $aok-icon-WC_genderneutral_2_rgb; 
  }
}
.aok-icon-WC_Herren_rgb {
  &:before {
    content: $aok-icon-WC_Herren_rgb; 
  }
}
.aok-icon-Wegweiser_rgb {
  &:before {
    content: $aok-icon-Wegweiser_rgb; 
  }
}
.aok-icon-Weltkugel_rgb {
  &:before {
    content: $aok-icon-Weltkugel_rgb; 
  }
}
.aok-icon-Wetter_rgb {
  &:before {
    content: $aok-icon-Wetter_rgb; 
  }
}
.aok-icon-Wickelraum_rgb {
  &:before {
    content: $aok-icon-Wickelraum_rgb; 
  }
}
.aok-icon-Wind_rgb {
  &:before {
    content: $aok-icon-Wind_rgb; 
  }
}
.aok-icon-Wohlbefinden_rgb {
  &:before {
    content: $aok-icon-Wohlbefinden_rgb; 
  }
}
.aok-icon-Wohnzimmer_rgb {
  &:before {
    content: $aok-icon-Wohnzimmer_rgb; 
  }
}
.aok-icon-Wolke_rgb {
  &:before {
    content: $aok-icon-Wolke_rgb; 
  }
}
.aok-icon-Yoga_rgb {
  &:before {
    content: $aok-icon-Yoga_rgb; 
  }
}
.aok-icon-Zahnmedizin_rgb {
  &:before {
    content: $aok-icon-Zahnmedizin_rgb; 
  }
}
.aok-icon-Zahnrad_rgb {
  &:before {
    content: $aok-icon-Zahnrad_rgb; 
  }
}
.aok-icon-Zeitraum_rgb {
  &:before {
    content: $aok-icon-Zeitraum_rgb; 
  }
}
.aok-icon-Zeitung_rgb {
  &:before {
    content: $aok-icon-Zeitung_rgb; 
  }
}
.aok-icon-Ziel_rgb {
  &:before {
    content: $aok-icon-Ziel_rgb; 
  }
}
.aok-icon-Zusatzversicherung_rgb {
  &:before {
    content: $aok-icon-Zusatzversicherung_rgb; 
  }
}
.aok-icon-Zuzahlung_rgb {
  &:before {
    content: $aok-icon-Zuzahlung_rgb; 
  }
}
.aok-icon-Zweitmeinung_rgb {
  &:before {
    content: $aok-icon-Zweitmeinung_rgb; 
  }
}

