@import "variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

.pagination {
  box-shadow: rgba(0, 0, 0, 0.13) 1px 2px 1px 0px;
  @include border-radius(0);
  .page-item {
    .current {
        cursor: default;
        color: $body-color;
      &:hover {
        background-color: #fff;
      }
    }

    &:first-child {
      .page-link {
        @include border-left-radius(0);
      }
    }
    &:last-child {
      .page-link {
        @include border-right-radius(0);
      }
    }
  }
}
