@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "icons_functional_aok_variables";

breadcrumb {
  display: inline-block;
  width: 100%;
}

.breadcrumb {
  width: 100%;
  padding: 0;
  font-weight: 300;
  margin-top: 20px;
  cursor: default;

  @include media-breakpoint-only(md) {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 40px;
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
  }
  
  .breadcrumb-link {
    color: #6d767c;
    text-decoration: none;
    line-height: 1rem;
    font-weight: 600;
    &:hover {
      color: #6d767c;
      text-decoration: none;
    }
  }

  .breadcrumb-item.active,
  .breadcrumb-item.active > span {
    font-weight: 300;
  }
}

.breadcrumb-item {
  font-size: $breadcrumb-font-size !important;
  display: flex;
  align-items: center;

  + .breadcrumb-item {
    &::before {
      padding-right: $breadcrumb-item-padding;
      color: $breadcrumb-divider-color;
      font-family: "aok-icons-functional-2023";
      content: $aok-icon-functional-Arrow-Right;
      font-weight: 300;
      margin-top: -1px;
    }
  }
}

.breadcrumb-link > .icon {
  font-size: 1rem;
  margin-top: -4px;
  font-weight: 300;
}
