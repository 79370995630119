@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "icons_functional_variables";
@import "icons_aok_variables";
@import "icons_functional_aok_variables";

.header_logo {
  display: block;
  width: auto;
  height: 60px;
  width: 110px;
}

.header-wrapper {
  position: relative;
  z-index: $zindex-fixed;
}

.header-servicebar {
  display: none;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5625rem 0.625rem;
}

.header-title {
  margin-right: auto;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  color: #586167;
  @include media-breakpoint-up(lg) {
    margin-left: 25px;
  }
  .header-title_item {
    line-height: normal;
    display: flex;
    .header-title_title {
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 1.5;
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
    .header-title_value {
      font-size: 0.875rem;
      font-weight: 700;
      @include media-breakpoint-up(lg) {
        font-size: 1.625rem;
      }
    }
  }
}

.header-stoerer {
  margin-right: auto;
  margin-left: 10px;
  display: flex;
  height: 40%;
  max-height: 150px;
  margin: 20px;
  cursor: pointer;
}

.header-stoerer-mobile {
  position: fixed;
  z-index:100;
  right: 10px;
  left: auto;
  max-height: 109px;
}


.header-buttons {
  display: flex;
  flex-direction: column;
  ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  li:first-child {
    .btn {
      margin-bottom: 0.83333rem;
    }
  }
}

.header-tenant_logo {
  margin: 0 20px 0 auto;
  img {
    display: block;
    width: 60px;
    height: auto;
  }
  @include media-breakpoint-down(md) {
    height: 2.77778rem;
    margin-right: 20px;
    padding: 0.55556rem 0;
    img {
      display: block;
      height: 100%;
      width: auto;
    }
  }
}

.header-score {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px rgb(195, 195, 195);
  align-items: flex-end;
  background-color: transparent;

  .score_label {
    font-size: 14px;
    font-weight: 400;
  }

  .score_value {
    font-size: 18px;
    font-weight: 700;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: column;
    border-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    .header-score-item {
      display: flex;
      padding: 0.6666666666666666rem 1.5rem;
      &.score_label {
        flex-grow: 1;
        line-height: 29px;
      }
      &.score_value {
        border-left: solid 1px rgb(195, 195, 195);
        flex-grow: 0;
        justify-self: flex-end;
        line-height: 29px;
        font-weight: $font-weight-bold;
      }
    }
  }
}

.header-account {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header-navigation {
  position: fixed;
  width: 270px;
  height: 100vh;
  right: -270px;
  z-index: 940;
  top: 0;
  flex-direction: row;
  align-items: flex-start;
  // background-color: #f6f6f6;
  box-shadow: 0px 24px 24px -20px rgba(0, 0, 0, 0.14);
  .main-navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .main-navigation_item {
      display: flex;
      flex-direction: column;
      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        li {
          list-style-type: none;
          margin-right: 0.5555555555555556rem;

          @include media-breakpoint-up(lg) {
            margin-right: 0.5555555555555556rem;
          }
          @include media-breakpoint-up(xl) {
            margin-right: 1.3888888888888888rem;
          }
          a.nav-item {
            font-size: 16px;
            color: #293033;
            display: block;
            position: relative;
            padding: 1.25rem 1.5rem;
            @include media-breakpoint-up(md) {
              padding: 0.75rem 0.375rem;
            }
            text-decoration: none;
            &:hover {
              text-decoration: none;
            }
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              display: block;
              width: 100%;
              height: 0.125rem;
              background: #b3b4b3;
              opacity: 0;
              transition: opacity 250ms;
            }
          }
          &:hover {
            a.nav-item {
              color: #005e3f;
              &::after {
                background: #005e3f;
                opacity: 1;
              }
            }
          }
          &.active {
            a.nav-item {
              color: $primary;
              &::after {
                background: $primary;
                opacity: 1;
              }
            }
          }
        }
      }
      @include media-breakpoint-up(lg) {
        .account-selector-cart,
        .account-selector-notifyer {
          display: flex;
          flex: 0 1 auto;
          justify-content: flex-end;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .header-navigation {
    position: relative;
    width: auto;
    height: auto;
    right: 0;
    box-shadow: 0px 24px 24px -20px rgba(0, 0, 0, 0.14);
    .main-navigation {
      flex-direction: row;
      align-items: center;
      .main-navigation_item {
        flex-direction: row;
        &:first-child {
          margin-right: auto;
        }
        > ul {
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .header_logo {
    height: 70px;
    width: 130px;
  }
  .header-servicebar {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 2px #dfe3e6;
    .header-servicebar_item {
      padding: 0.83333rem;
      &:first-child {
        margin-right: auto;
      }
      .link-contact {
        color: #6d767c;
        text-decoration: none;
        display: flex;
        align-items: center;
        font-weight: $font-weight-normal;
        &:before {
          content: $aok-icon-Telefon_rgb;
          font-family: "aok-icons-2023";
          font-size: 20px;
          padding-right: 10px;
          font-weight: 600;
          margin-top: -4px;
        }
        &:hover {
          color: $primary;
        }
      }
      .link-back {
        margin-right: auto;
        color: #6b6b6b;
        text-decoration: none;
        font-weight: $font-weight-normal;
        &:before {
          font: 14px/1 "aok-icons-functional-2023";
          content: $aok-icon-functional-Arrow-Left;
          margin-right: 0.41667rem;
          color: #6b6b6b;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .header_logo {
    width: 170px;
    height: 93px;
  }
}

.header-mobile {
  padding: 0 1.5rem !important;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 24px 24px -20px rgba(0, 0, 0, 0.14);
  .header_logo {
    height: 2rem;
  }
  .header-navigation-toggler {
    // margin-right: 0.625rem;
    .navigation-toggler {
      padding: 0.25rem 0.75rem;
      font-size: 1.625rem;
      line-height: 1;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      transition: box-shadow 0.15s ease-in-out;
      color: #6d767c;

      &:hover {
        color: #005e3f;
      }

      span {
        display: block;
      }
    }
  }
}

.dropdown-menu {
  border-top: none;
  border-radius: 0;
}

@keyframes selector_animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.client-selector {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.83333rem;
  transition: box-shadow 0.2s;
  &.show {
    box-shadow: 0 0.11111rem 0.22222rem 0 rgba(0, 0, 0, 0.13);
    .client-selector_selection {
      &::after {
        font-family: "aok-icons-functional-2023";
        content: $aok-icon-functional-Arrow-Up;
      }
    }
  }
  .dropdown-menu {
    animation-name: selector_animate;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    margin: 0;
    top: 50px !important;
    border: none;
    transform: none !important;
    opacity: 0;
    height: auto;
    .dropdown-item {
      font-weight: $font-weight-light;
      display: inline;
      font-size: 16px;
      padding: 0;
      text-decoration: none;
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
        background-color: #fff;
        color: $primary;
      }
    }
    &.show {
      opacity: 1;
      box-shadow: 0 0.11111rem 0.22222rem 0 rgba(0, 0, 0, 0.13);
    }
  }
  .client-selector_selection {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #6d767c;
    &::after {
      font-family: "aok-icons-functional-2023";
      content: $aok-icon-functional-Arrow-Down;
      margin-left: 0.55556rem;
      color: inherit;
    }
    &:hover {
      color: $primary;
    }
  }
  .client-selector_label {
    font-size: 14px;
    font-weight: $font-weight-normal;
    margin-right: 0.83333rem;
  }
  .client-selector_current {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-weight: $font-weight-normal;
    color: inherit;
    &::before {
      font: 23px/1 aok-icons-2023;
      content: $aok-icon-Location_rgb;
      font-size: 20px;
      font-weight: 600;
      padding-right: 10px;
      margin-top: -4px;
    }
  }
  ul {
    padding-inline-start: 0;
    padding: 0 20px;
    > li {
      list-style-type: none;
      line-height: 28px;
    }
  }
  &.isMobile {
    .dropdown-menu {
      &.show {
        position: relative !important;
        transform: none !important;
      }
    }
  }
}

.account-selector {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.83333rem;
  //transition: box-shadow 0.2s;
  min-width: 240px;
  border-bottom: 0px solid #c3c3c3;

  .dropdown {
    width: 100%;
    a.dropdown-toggle {
      &::after {
        content: none;
      }
    }
  }
  &.show {
    box-shadow: none;

    @include media-breakpoint-up(lg) {
      box-shadow: 0 0.11111rem 0.22222rem 0 rgba(0, 0, 0, 0.13);
    }
    .account-selector_selection {
      .account-selector_name {
        &::after {
          font-family: "aok-icons-functional-2023";
          content: $aok-icon-functional-Arrow-Up;
        }
      }
    }
  }
  .dropdown-menu {
    animation-name: selector_animate;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    margin: 0;
    padding: 0;
    top: 75px !important;
    border: none;
    transform: none !important;
    opacity: 0;
    height: auto;
    .btn-secondary_default {
      text-decoration: none;
    }
    .dropdown-item {
      font-weight: $font-weight-normal;
      display: inline;
      text-decoration: none;
      padding: 0;
      width: 100%;
      display: inline;
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
        background-color: #fff;
        color: $primary;
      }
    }
    &.show {
      box-shadow: none;
      @include media-breakpoint-up(lg) {
        box-shadow: 0 0.11111rem 0.22222rem 0 rgba(0, 0, 0, 0.13);
      }
    }
  }
  .account-selector_selection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    text-decoration: none;
    color: #303030;
  }
  .account-selector_label {
    font-size: 14px;
    font-weight: $font-weight-normal;
    margin-right: 0.83333rem;
  }
  .account-selector_name {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: $body-color;
    white-space: nowrap;
    &::after {
      font-family: "aok-icons-functional-2023";
      content: $aok-icon-functional-Arrow-Down;
      margin-left: 0.55556rem;
      color: #c3c3c3;
      margin-left: auto;
      font-weight: 300;
    }
  }
  ul {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding: 20px;
    > li {
      list-style-type: none;
      line-height: 28px;
      &:not(:last-child) {
        margin-bottom: 0.5555555555555556rem;
      }
      .btn {
        margin-top: 0.8333333333333334rem;
        min-width: 100%;
        width: 100%;
      }
    }
  }
}

span.btn-icon_before {
  margin-right: 10px;
}

@include media-breakpoint-down(md) {
  .account-selector-cart,
  .account-selector-notifyer {
    padding-bottom: 0px !important;
    .btn-cart {
      min-width: 245px;
      display: inline-block;
      position: relative;

      &:hover {
        color: #005e3f;
      }

      .text {
        display: block !important;
        margin-left: -20px;
      }

      .badge {
        position: absolute;
        top: 12px;
        right: 68px;
        background: #005e3f;
        color: #fff;
      }
    }
  }
}

.account-selector-cart,
.header-score,
.account-selector-notifyer {
  &:not(:last-of-type) {
    border-right: 1px solid #ccc;
    height: 47px;
    display: flex;
    align-items: center;
  }
}
.header-score {
  align-items: flex-end;
}

.header-mobile {
  padding: 0 1.5rem;
  .account-selector-cart {
    .btn-cart {
      min-width: auto;
      display: block;
      position: relative;
      text-decoration: none;
      color: #6d767c;

      &:hover {
        color: #005e3f;
      }

      i {
        top: 3px;
        margin-right: 0px;
      }

      .badge {
        position: relative;
        top: -1px;

        background: #005e3f;
        color: #fff;
      }

      .news-info-icon {
        background: red;
        position: absolute;
        width: 10px;
        height: 10px;
        left: 14px;
        border-radius: 16px;
        border: 2px solid #fff;
      }
    }
  }

  .account-selector-notifyer {
    @extend .account-selector-cart;
  }
}

@include media-breakpoint-up(lg) {
  .account-selector-cart,
  .account-selector-notifyer {
    .btn-cart {
      display: block;
      position: relative;
      text-decoration: none;
      color: #6d767c;

      &:hover {
        color: #005e3f;
      }

      i {
        top: 3px;
        margin-right: 0px;
      }

      .badge {
        position: relative;
        top: -1px;

        background: #005e3f;
        color: #fff;
      }

      .news-info-icon {
        background: red;
        position: absolute;
        width: 10px;
        height: 10px;
        left: 14px;
        border-radius: 16px;
        border: 2px solid #fff;
      }
    }
  }
}

.page-wrapper--acvite-mobile-nav {
  .main-navigation {
    .main-navigation_item {
      ul {
        li {
          a.nav-item {
            padding: 1.25rem 1.5rem;
          }
        }
      }
    }
  }

  .score_label {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px !important;
  }
  .score_value {
    line-height: 23px !important;
  }

  .account-selector-notifyer {
    padding: 12px 1rem !important;
  }

  .account-selector-cart {
    text-align: center;
    padding: 17px 0px;
    border-bottom: unset !important;

    display: block;
  }
  .account-selector {
    padding: 14px 0px;
    border-bottom: 1px solid #c3c3c3;
    .account-selector-dropdown-headline {
      font-size: 14px;
    }
    a:not(.btn) {
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        color: #212529;
        text-decoration: none !important;
      }
    }
    .dropdown {
      &::after {
        font-family: "AOK-Icons-Functional";
        content: $icon-arrow-down4;
        margin-left: 0.55556rem;
        color: #c3c3c3;
        margin-left: 10px;
        position: absolute;
        right: 28px;
        top: 16px;
        z-index: -1;
      }
      &.show {
        &::after {
          content: $icon-arrow-up4;
        }
      }
    }
    .dropdown-item {
      text-decoration: none;
    }
    .dropdown-menu {
      &.show {
        box-shadow: unset;
      }
      position: relative !important;
      top: 0px !important;
      background-color: transparent;
      ul {
        li {
          border-bottom: 0px solid #c3c3c3;
          margin-bottom: 0px;
          margin-top: 10px;
          &.w-100 {
            .dropdown-item {
              padding: 0px 24px;
              display: inline-block;
              &:hover {
                background: transparent;
              }
              a:not(.btn) {
                background: #fff;
                line-height: 44px;
                height: 46px;
                font-weight: 500;
              }
            }
          }
          a:not(.btn) {
            background-color: transparent;
            padding: 0px 23px !important;
            font-weight: 300;
            &:hover,
            &:focus,
            &:active {
              background-color: transparent;
              color: #212529;
            }
          }
        }
      }
    }
  }

  .client-selector {
    border-bottom: 1px solid #c3c3c3;
    padding: 1.11111rem 1.38889rem;
    .dropdown {
      width: 100%;
      padding: 0 0 0 1.25rem;

      &::before {
        font: 23px/1 aok-icons-2023;
        content: $aok-icon-Location_rgb;
        font-weight: 600;
        font-size: 20px;
        padding-right: 0.83333rem;
        margin-right: -10px;
        margin-left: -10px;
        color: $primary;
        position: absolute;
        top: 13px;
        left: 0;
      }
      &::after {
        font-family: "AOK-Icons-Functional";
        content: $icon-arrow-down4;
        margin-left: 0.55556rem;
        color: #c3c3c3;
        margin-left: 10px;
        position: absolute;
        right: 6px;
        top: 12px;
      }
      &.show {
        &::after {
          content: $icon-arrow-up4;
        }
      }
    }
    .dropdown-menu {
      top: 0px !important;
      background-color: transparent;

      &.show {
        opacity: 1;
        box-shadow: 0rem 0rem 0 rgba(0, 0, 0, 0%);
      }
      ul {
        margin: 0;
        font-size: 0.88889rem;
        font-weight: 300;
        line-height: 1.75;
        list-style: none;
        li {
          border-bottom: 0px;
          a {
            padding: 0px !important;
            // padding-left: 20px !important;
            line-height: 1.75;
            &:hover {
              background: transparent;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .client-selectorDropdownWrapper {
    cursor: pointer;
    width: 100%;
    white-space: normal;
    padding-right: 1.25rem;
    &::before {
      content: "";
      padding-right: 15px;
      color: #4fc579;
      font-size: 1.38889rem;
      vertical-align: bottom;
      position: absolute;
    }
    &::after {
      content: "";
      display: none;
    }
    .client-selectorHeaderTitle {
      font-size: 0.66667rem;
    }
    .client-selectorTenant {
      color: $brand-aok-green;
      line-height: 1.4;
      text-decoration: none;
    }
  }
  .main-navigation_item {
    ul {
      li {
        border-bottom: 1px solid #c3c3c3;
        width: 100%;
        &:hover {
          a {
            &::after {
              background: #f6f6f6 !important;
              opacity: 0 !important;
            }
          }
        }
        &.active {
          a {
            &::after {
              background: #f6f6f6 !important;
              opacity: 0 !important;
            }
          }
        }
        a:not(.btn) {
          padding: 1.11111rem 1.38889rem;
          font-size: 16px !important;
        }
      }
    }
  }
}

.mobile-action-bar-login-wrapper {
  background-color: #fff;
  display: none;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.4);

  &.fixed-bottom {
    z-index: 500;
  }

  .btn {
    background-color: #fff;
    color: #303030;
    border: 1px solid #fff;
    font-size: 12px;
    border-right: 1px solid #c1c1be;
    height: 60px;
    padding: 7px 0 2px;

    .badge {
      position: absolute;
      top: 4px;
      margin-left: 10px;
      color: #f6f6f6 !important;
      background-color: #005e3f;
      font-size: 12px;
    }

    .icon {
      width: 100%;
      text-align: center;
      color: #b3b4b3;
      font-size: 22px;
      line-height: 22px;
      display: block;
    }

    .text {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 28px;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
    }
  }

  @include media-breakpoint-down(md) {
    display: block;

    .btn {
      min-width: unset;
    }
  }
}