@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
h1.content_title,
.content_title {
    font-weight: 600;
    margin-left: 20px !important;
    margin-right: 0 !important;
    @include media-breakpoint-up(lg) {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.content-wrapper {
    padding: 1rem 0.5rem 2rem 0.5rem;
    @include media-breakpoint-up(lg) {
        padding: 20px 0 40px;
    }
}

.content-container {
    padding: 20px;
    @include media-breakpoint-up(lg) {
        padding: 40px;
    }
}

.container-stoerer {
    display: flex;
    align-items: center;
    margin: 0px;
}

.content-text-green{
    color: $brand-aok-green !important;
}

.content-text-red{
    color: #dc3545 !important;
}

.content-text-black{
    color: #000000 !important;
}

.stoerer-praemienshop{
    z-index:100;
    right: 300px;
    top: -15px;
    max-height: 200px;
    margin-left: 50px;
}