@import "~bootstrap/scss/_functions.scss";
@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/_mixins.scss";
@import "icons_aok_variables";

.bg-gray {
  background-color: #edefef;
}

.bg-white {
  background-color: #ffffff;
}

.bg-white_alpha_08 {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}

.bg-off-white {
  background-color: #e8f4f2;
}

.text-black {
  color: #000 !important;
}

.text-bold {
  font-weight: bold;
}

.text-brand-primary {
  color: $primary;
}

.text-lightgreen {
  color: #50eb5d !important;
}

.button-wrapper {
  display: flex;
  justify-content: flex-start;
  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }
  margin-top: 1.5rem;
}

.page-link {
  &:focus {
    box-shadow: none;
  }
}

.tip-text {
  font-size: 14px;
  line-height: 1.45;
  display: flex;
  align-items: center;
  font-weight: 600;
  a {
    font-size: 14px;
    font-weight: 600;
  }
  .tip-text-icon {
    margin-right: 20px;
    &:before {
      font-family: "aok-icons-2023";
      content: $aok-icon-Sprechblasen_rgb;
      margin-left: 0.55556rem;
      color: $primary;
      margin-left: 10px;
      font-size: 1.5555555555555556rem;
    }
  }
  .tip-text-icon-warning {
    margin-right: 20px;
    &:before {
      font-family: "aok-icons-2023";
      content: $aok-icon-Sprechblase_mit_Ausrufezeichen_rgb;
      margin-left: 0.55556rem;
      color: $primary;
      margin-left: 10px;
      font-size: 1.5555555555555556rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
}

.prepanded {
  label,
  small {
    margin-left: 65px;
  }
}

.shape {
  margin-top: 32px;
  margin-right: 1rem;
  @include media-breakpoint-up(lg) {
    margin: 5px;
  }
  &.circle {
    color: #fff;
    background: #3da562;
    border-radius: 50%;
    flex: 0 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-weight: 400;
  }
}

.no-scroll {
  overflow: hidden;
}

.flex-50 {
  flex: 1 0 50%;
}
.flex-25 {
  flex: 1 0 25%;
}

.grow {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.25s;
  transform-origin: center;
}
.grow:hover {
  transform: matrix(1.25, 0, 0, 1.25, 0, 0);
}

.text-underline {
  text-decoration: underline !important;
}
.fw-300 {
  font-weight: 300 !important;
}