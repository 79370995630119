@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

.module-premium-shop {
  display: flex;
  flex-direction: column;

  .premium-shop-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0;
    height: 300px;
    display: flex;

    .inner-img {
      width: 100%;
      background-position: center;
      background-size: cover;
      height: 200px;
    }

    @include media-breakpoint-up(md) {
      height: 200px;

      .inner-img {
        width: calc((100% / 12) * 7);
      }
    }
  }

  .heading-premiumShop {
    // display: none;

    width: 100%;

    @include media-breakpoint-up(md) {
      display: flex;
      width: calc((100% / 12) * 5);
      height: 100%;
    }

    .headingContent-premiumShop {
      display: flex;
      justify-content: end;
      flex-direction: column;
      padding: 1rem;
      overflow: hidden;
      position: relative;
      height: 100%;

      > p {
        padding-bottom: 0;
      }

      div::before {
        content: "";
        background-color: $primary;
        // mix-blend-mode: multiply;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          background: rgba(2, 68, 27, 0.65);
          box-shadow: 0.55556rem 0 0 rgba(2, 68, 27, 0.65),
            -0.55556rem 0 0 rgba(2, 68, 27, 0.65);
        }
      }

      &::before {
        content: none;
      }

      .z100 {
        z-index: 100;
      }
    }
  }

  .heading-premiumShop-Mobile {
    display: flex;
    width: 100%;
    align-items: flex-end;

    @include media-breakpoint-up(md) {
      display: none;
    }

    .headingContent-premiumShop-Mobile {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      flex-direction: column;
      padding: 1rem;
      overflow: hidden;
      position: relative;

      div::before {
        content: "";
        background-color: $primary;
        // mix-blend-mode: multiply;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          background: rgba(2, 68, 27, 0.65);
          box-shadow: 0.55556rem 0 0 rgba(2, 68, 27, 0.65),
            -0.55556rem 0 0 rgba(2, 68, 27, 0.65);
        }
      }

      &::before {
        content: none;
      }

      .z100 {
        z-index: 100;
      }
    }
  }
}
