$icomoon-font-family2: "aok-icons-functional-2023";
$icomoon-font-path2: "../assets/fonts";

$aok-icon-functional-Adress-Book: "\e900";
$aok-icon-functional-Alert: "\e901";
$aok-icon-functional-Alert-Circle-Filled: "\e902";
$aok-icon-functional-Android: "\e903";
$aok-icon-functional-Apple: "\e904";
$aok-icon-functional-Arrow-Down: "\e905";
$aok-icon-functional-Arrow-Left: "\e906";
$aok-icon-functional-Arrow-Left-Circle-Filled: "\e907";
$aok-icon-functional-Arrow-Left-Outline: "\e908";
$aok-icon-functional-Arrow-Right: "\e909";
$aok-icon-functional-Arrow-Right-Circle: "\e90a";
$aok-icon-functional-Arrow-Right-Circle-Filled: "\e90b";
$aok-icon-functional-Arrow-Rounded-Down: "\e90c";
$aok-icon-functional-Arrow-Rounded-Left: "\e90d";
$aok-icon-functional-Arrow-Rounded-Leftdown: "\e90e";
$aok-icon-functional-Arrow-Rounded-Leftup: "\e90f";
$aok-icon-functional-Arrow-Rounded-Right: "\e910";
$aok-icon-functional-Arrow-Rounded-Rightdown: "\e911";
$aok-icon-functional-Arrow-Rounded-Rightup: "\e912";
$aok-icon-functional-Arrow-Rounded-Up: "\e913";
$aok-icon-functional-Arrow-Small-Down: "\e914";
$aok-icon-functional-Arrow-Small-Left: "\e915";
$aok-icon-functional-Arrow-Small-Right: "\e916";
$aok-icon-functional-Arrow-Small-Up: "\e917";
$aok-icon-functional-Arrow-Up: "\e918";
$aok-icon-functional-Audio: "\e919";
$aok-icon-functional-Backwards: "\e91a";
$aok-icon-functional-Become-member: "\e91b";
$aok-icon-functional-Bookmark: "\e91c";
$aok-icon-functional-Bookmark-Filled: "\e91d";
$aok-icon-functional-Bookmarks-List: "\e91e";
$aok-icon-functional-Calendar: "\e91f";
$aok-icon-functional-Calendar-Check: "\e920";
$aok-icon-functional-Cancel-Circle: "\e921";
$aok-icon-functional-Cancel-Circle-Filled: "\e922";
$aok-icon-functional-Caret-Down: "\e923";
$aok-icon-functional-Caret-Left: "\e924";
$aok-icon-functional-Caret-Right: "\e925";
$aok-icon-functional-Caret-Select: "\e926";
$aok-icon-functional-Caret-Up: "\e927";
$aok-icon-functional-Chat: "\e928";
$aok-icon-functional-Checkmark: "\e929";
$aok-icon-functional-Checkmark-Circle: "\e92a";
$aok-icon-functional-Checkmark-Circle-Filled: "\e92b";
$aok-icon-functional-Clipboard: "\e92c";
$aok-icon-functional-Clock: "\e92d";
$aok-icon-functional-Close: "\e92e";
$aok-icon-functional-Close-small: "\e92f";
$aok-icon-functional-Compare: "\e930";
$aok-icon-functional-Copy: "\e931";
$aok-icon-functional-Delete: "\e932";
$aok-icon-functional-Documents: "\e933";
$aok-icon-functional-Download: "\e934";
$aok-icon-functional-Download-Circle-Filled: "\e935";
$aok-icon-functional-Download-Folder: "\e936";
$aok-icon-functional-Enlarge: "\e937";
$aok-icon-functional-Enter: "\e938";
$aok-icon-functional-Equalizer: "\e939";
$aok-icon-functional-Exit: "\e93a";
$aok-icon-functional-Expand-Arrow: "\e93b";
$aok-icon-functional-Fax: "\e93c";
$aok-icon-functional-Feedback: "\e93d";
$aok-icon-functional-Filter: "\e93e";
$aok-icon-functional-Flag: "\e93f";
$aok-icon-functional-Folder: "\e940";
$aok-icon-functional-Formulare-Anfrage: "\e941";
$aok-icon-functional-Forward: "\e942";
$aok-icon-functional-Gear-Settings: "\e943";
$aok-icon-functional-Globe: "\e944";
$aok-icon-functional-GooglePlus: "\e945";
$aok-icon-functional-Grid: "\e946";
$aok-icon-functional-Headphones: "\e947";
$aok-icon-functional-Heart: "\e948";
$aok-icon-functional-Heart-Light: "\e949";
$aok-icon-functional-Home: "\e94a";
$aok-icon-functional-Image: "\e94b";
$aok-icon-functional-Images: "\e94c";
$aok-icon-functional-Info: "\e94d";
$aok-icon-functional-Info-Circle-Filled: "\e94e";
$aok-icon-functional-Keyword: "\e94f";
$aok-icon-functional-Kundencenter: "\e950";
$aok-icon-functional-List: "\e951";
$aok-icon-functional-Load: "\e952";
$aok-icon-functional-Location: "\e953";
$aok-icon-functional-Lock: "\e954";
$aok-icon-functional-Login: "\e955";
$aok-icon-functional-Mail: "\e956";
$aok-icon-functional-Mailbox: "\e957";
$aok-icon-functional-Map: "\e958";
$aok-icon-functional-Menu: "\e959";
$aok-icon-functional-Menu-Mobile: "\e95a";
$aok-icon-functional-Mic: "\e95b";
$aok-icon-functional-Minus: "\e95c";
$aok-icon-functional-Mitglied-werden-Empfehlen: "\e95d";
$aok-icon-functional-New-Tab: "\e95e";
$aok-icon-functional-Office: "\e95f";
$aok-icon-functional-Parking: "\e960";
$aok-icon-functional-Pause: "\e961";
$aok-icon-functional-Pause-Circle: "\e962";
$aok-icon-functional-Pause-Circle-Filled: "\e963";
$aok-icon-functional-PDF-file: "\e964";
$aok-icon-functional-Pencil-Edit: "\e965";
$aok-icon-functional-Phone: "\e966";
$aok-icon-functional-Play-Circle: "\e967";
$aok-icon-functional-Play-Circle-Filled: "\e968";
$aok-icon-functional-Play-Filled: "\e969";
$aok-icon-functional-Play-Outline: "\e96a";
$aok-icon-functional-Plus: "\e96b";
$aok-icon-functional-Podcast: "\e96c";
$aok-icon-functional-Power: "\e96d";
$aok-icon-functional-Print: "\e96e";
$aok-icon-functional-Proximity-Search: "\e96f";
$aok-icon-functional-Question-Circle: "\e970";
$aok-icon-functional-Questionmark-filled: "\e971";
$aok-icon-functional-Rueckrufservice: "\e972";
$aok-icon-functional-Screen-Share: "\e973";
$aok-icon-functional-Search: "\e974";
$aok-icon-functional-Share: "\e975";
$aok-icon-functional-Shrink: "\e976";
$aok-icon-functional-Sign: "\e977";
$aok-icon-functional-Social-Facebook: "\e978";
$aok-icon-functional-Social-Instagram: "\e979";
$aok-icon-functional-Social-Kununu: "\e97a";
$aok-icon-functional-Social-LinkedIn: "\e97b";
$aok-icon-functional-Social-Twitter: "\e97c";
$aok-icon-functional-Social-Xing: "\e97d";
$aok-icon-functional-Social-YouTube: "\e97e";
$aok-icon-functional-Sort: "\e97f";
$aok-icon-functional-Soundcloud: "\e980";
$aok-icon-functional-Speech-Bubbble: "\e981";
$aok-icon-functional-Spotify: "\e982";
$aok-icon-functional-Sprache-Gebaerde: "\e983";
$aok-icon-functional-Sprache-Leicht: "\e984";
$aok-icon-functional-Star-Filled: "\e985";
$aok-icon-functional-Synonym: "\e986";
$aok-icon-functional-Target: "\e987";
$aok-icon-functional-Top: "\e988";
$aok-icon-functional-Train: "\e989";
$aok-icon-functional-Unlock: "\e98a";
$aok-icon-functional-Video-Camera: "\e98b";
$aok-icon-functional-Video-Chat: "\e98c";
$aok-icon-functional-Virus: "\e98d";
$aok-icon-functional-Volume_decrease: "\e98e";
$aok-icon-functional-Volume_increase: "\e98f";
$aok-icon-functional-Volume_medium: "\e990";
$aok-icon-functional-Volume_mute: "\e991";
$aok-icon-functional-WhatsApp: "\e992";
$aok-icon-functional-Windows: "\e993";
$aok-icon-functional-Zoom_in: "\e994";
$aok-icon-functional-Zoom_out: "\e995";
$aok-icon-functional-Zurueck-Vor: "\e996";

