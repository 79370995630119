ul {
  padding-inline-start: 1.75em;
}

ol {
  padding-inline-start: 20px;
  li {
    &::marker {
      font-weight: 400;
    }
  }
}
