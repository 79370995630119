@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

.accordion {
  h3 {
    //color: $primary;
    margin: 1rem 0 0.5rem 0;
    font-weight: 500;
  }

  div:not(:first-child) h2 {
    margin-top: 2rem;
  }
}
