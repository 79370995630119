@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "icons_functional_aok_variables";

.scheckheft-details {
  .card {
    border: 0px;
    border-radius: 0px;
    margin: 10px 0px;

    .card-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 0px solid rgba(0, 0, 0, 0.125);
    }
  }

  .btn-toggle {
    height: auto;
    
    @include hover-focus {
      color: #18ab42;
      text-decoration: none;
    }
    span[class*="icon-"] {
      color: #18ab42;

      &:before {
        content: $aok-icon-functional-Minus;
      }

      &:hover {
        color: #18ab42;
      }
    }
    &.collapsed {
      span[class*="icon-"] {
        color: #40484d;
        &:before {
          content: $aok-icon-functional-Plus;
        }

        &:hover {
          color: #18ab42;
          &:before {
            content: $aok-icon-functional-Minus;
          }
        }
      }
    }
  }

  .btn-scheckheft-status-text {
    color: #303030;
  }
  .displayStatus {
    font-size: .875rem;
    color: #303030;
    font-weight: bold;
  }
}

.select-scheckheft-table-wrapper {
  .nav-item {
    .nav-link {
      border-top-left-radius: unset;
      border-top-right-radius: unset;

      &.active {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          background-color: $primary;
          height: 6px;
          top: -6px;
          left: -1px;
          width: 100%;
          border-left: solid 1px $primary;
          border-right: solid 1px $primary;
          box-sizing: content-box;
        }
      }
    }
  }

  .schekheft-tip_icon {
    margin-right: 10px;
  }
}
