@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/card";
@import "helper";
@import "icons_functional_variables";
@import "icons_functional_aok_variables";

// number of cards per line for each breakpoint
$cards-per-line: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
);
//Create Card width per lines
@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .aok-card-deck .aok-card {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(
        #{100 / map-get($cards-per-line, $name)}% - #{$grid-gutter-width}
      );
    }
  }
}

aok-premium-shop-card {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.module-aok-card {
  display: flex;
  flex-direction: column;
  .module-aok-card-footer {
    @extend .button-wrapper;
  }
}
.aok-card-wrapper-flex {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.aok-card-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  &:hover {
    cursor: pointer;
    .aok-card-title,
    .aok-card-category,
    .aok-card-link {
      color: #18ab42 !important;
    }
  }
}

.new-card-detail {
  .subTitle {
    font-size: smaller;
    color: #838383;
    font-weight: 400;
    margin-bottom: 1rem !important;
  }
}

.aok-card-deck {
  @extend .card-deck;
  margin: -$card-deck-margin;
  .aok-card {
    @extend .card;
    border: none;
    border-radius: 0;
    margin: $card-deck-margin;
    box-shadow: none;
    @include media-breakpoint-up(md) {
      // flex: 1 0 0%;
      // // margin-left: $card-deck-margin;
      // // margin-right: $card-deck-margin;
    }
    &:first-child {
      @include media-breakpoint-up(md) {
        // //  margin-left: 0;
        // margin-right: -$card-deck-margin;
      }
    }
    &:last-child {
      @include media-breakpoint-up(md) {
        // margin-left: -$card-deck-margin;
        // //  margin-right: 0;
      }
    }
    .aok-card-image {
      // @extend .card-img-top;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 0;
      flex: 0 1 auto;
      width: 100%;
      height: 200px;
    }

    aok-premium-shop-card {
      .aok-card-image {
        background-size: contain;
        width: auto;
        margin: 1rem;
      }
    }
    .aok-card-body {
      @extend .card-body;
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      h2.aok-card-category {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 0;
      }
      .aok-card-link {
        margin-top: auto;
        text-decoration: none;
        color: $primary;
        &:hover {
          text-decoration: none;
        }
      }
      .aok-premium-shop-card-point {
        margin-bottom: 0;
        font-weight: 700;
      }
      .aok-premium-shop-card-body {
        @extend .card-body;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        flex: 1 0 auto;
        word-break: break-word;
        align-items: flex-end;
        justify-content: flex-start;
        @include media-breakpoint-up(md) {
          justify-content: flex-end;
        }
        // .aok-premium-shop-card-point {
        //   margin-bottom: 0;
        //   font-size: 14px;
        //   font-weight: 400;
        // }
        .aok-premium-shop-card-controls {
          display: flex;
          flex-direction: row;

          .aok-premium-shop-card-link {
            margin-top: auto;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }

          .last {
            margin-right: 0 !important;
          }
          .first {
            margin-left: 0 !important;
          }

          .visited {
            color: $primary;
            opacity: 0.5;
            margin-right: 1em;
            &::after {
              content: $aok-icon-functional-Checkmark ;
              font-family: "aok-icons-functional-2023";
              opacity: 0.5;
              font-size: 1em;
              position: absolute;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}
