@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

.cart-table {
  border-collapse: collapse;

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;

    thead {
      display: table-header-group;
      vertical-align: middle;
    }

    tbody {
      // display: flex;
      // flex-direction: column;
      // flex: 1 0 100%;
      display: block;
      tr {
        display: flex;
        flex-direction: column;
        flex: 1 0 100%;

        td {
          padding-left: 150px;
          position: relative;
        }
      }
    }
    tfoot {
      display: block;
    }
  }

  thead th {
    border-right: solid 3px $table-border-color;
    padding: 12px 20px;

    @include media-breakpoint-down(sm) {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  }

  tbody td {
    border: none;
    vertical-align: middle;
    padding: 0.66667rem 1.11111rem;

    &:not(:first-child) {
      border-top: 1px solid #edefef;
      @include media-breakpoint-up(md) {
        border-top: 0;
      }
    }
  }
  .card-item {
    &:not(:last-child) {
      border-bottom: 1px solid #c3c3c3;
    }
    .cart-table_product,
    .cart-item_amount,
    .cart-item_value,
    .cart-item_total {
      @include media-breakpoint-down(sm) {
        &:before {
          content: attr(data-title);
          position: absolute;
          padding: 12px;
          top: 0;
          left: 0;
          font-weight: 700;
        }
      }
    }
    .cart-item_amount {
      @include media-breakpoint-down(sm) {
        &:before {
          top: 10px;
        }
      }
    }
  }
  .cart-table_product {
    width: 50%;

    .cart-item_product-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

    
      .cart-item_product-name {
        align-self: center;
        order: 2;

        @include media-breakpoint-up(md) {
          align-self: flex-start;
          order: 1;
        }
      }
      .cart-item_product-image {
        max-width: 100%;
        width: 300px;
       
        order: 1;
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
          max-width: 70%;
          width: 200px;
          order: 2;
          margin-bottom: 0;
        }
      }
    }
  }
  .cart-item_amount {
    .cart-item_amount-input {
      text-align: right;

      @include media-breakpoint-up(md) {
        width: 100px;
        margin: 0 auto;
      }
    }
  }

  .cart-item_value,
  .cart-item_total {
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }
  .cart-item_remove {
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }
  @include media-breakpoint-down(sm) {
    .cart-table_product,
    .cart-item_amount,
    .cart-item_value,
    .cart-item_total,
    .cart-item_product-wrapper,
    .cart-item_remove {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    // .cart-item_remove {
    //   width: 100%;
    // }
  }

  .cart-table_footer {
    background-color: $table-head-bg;

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
    }

    td {
      border-top: 0;

      @include media-breakpoint-down(sm) {
        &:last-child {
          display: none;
        }
      }
    }
    .cart-table_empty {
      text-align: right;

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }
    .cart-table_total {
      font-weight: 700;
      text-align: right;
    }
  }
}
