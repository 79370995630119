@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

$aok-font-path: "../assets/fonts/" !default;
$aok-font-family: "AOKBuenosAires";
$aok-font-family-text: "AOKBuenosAiresText";

@font-face {
  font-family: $aok-font-family;
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-Bold.eot"); /* IE9 Compat Modes */
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-Bold.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-Bold.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}
@font-face {
  font-family: $aok-font-family;
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-BoldItalic.eot"); /* IE9 Compat Modes */
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-BoldItalic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-BoldItalic.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}
@font-face {
  font-family: $aok-font-family;
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-Regular.eot"); /* IE9 Compat Modes */
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-Regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-Regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-Regular.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}
@font-face {
  font-family: $aok-font-family;
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-RegularItalic.eot"); /* IE9 Compat Modes */
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-RegularItalic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-RegularItalic.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}
@font-face {
  font-family: $aok-font-family;
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-SemiBold.eot"); /* IE9 Compat Modes */
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-SemiBold.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-SemiBold.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}
@font-face {
  font-family: $aok-font-family;
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-SemiBoldItalic.eot"); /* IE9 Compat Modes */
  src: url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-SemiBoldItalic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires_Web/AOKBuenosAires-SemiBoldItalic.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}
// -------------------------------
@font-face {
  font-family: $aok-font-family-text;
  // src: url('#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-Bold.eot'); /* IE9 Compat Modes */
  // src: url('#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  src: url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-Bold.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-Bold.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}

@font-face {
  font-family: $aok-font-family-text;
  // src: url('#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-BoldItalic.eot'); /* IE9 Compat Modes */
  // src: url(#{$aok-font-path}AOK-Buenos-Aires-Text_Web/'AOKBuenosAiresText-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  src: url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-BoldItalic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-BoldItalic.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}

@font-face {
  font-family: $aok-font-family-text;
  // src: url('#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-Regular.eot'); /* IE9 Compat Modes */
  // src: url('#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  src: url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-Regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-Regular.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}

@font-face {
  font-family: $aok-font-family-text;
  // src: url('#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-RegularItalic.eot'); /* IE9 Compat Modes */
  // src: url('#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-RegularItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  src: url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-RegularItalic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-RegularItalic.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}

@font-face {
  font-family: $aok-font-family-text;
  // src: url('#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-SemiBold.eot'); /* IE9 Compat Modes */
  // src: url('#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  src: url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-SemiBold.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-SemiBold.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}

@font-face {
  font-family: $aok-font-family-text;
  // src: url('#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-SemiBoldItalic.eot'); /* IE9 Compat Modes */
  // src: url('#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  src: url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-SemiBoldItalic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$aok-font-path}AOK-Buenos-Aires-Text_Web/AOKBuenosAiresText-SemiBoldItalic.woff")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}

select,
option {
  font: 300 16px $aok-font-family-text, Arial, sans-serif !important;
}

a {
  font-size: 16px;
  font-weight: 300;
  color: #18ab42;
  text-decoration: underline;
  &:hover {
    color: $primary;
  }
}

.invalid-feedback a {
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: $aok-font-family;
  color: #005e3f !important;
}

h1,
.h1 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
}
h2,
.h2 {
  font-size: 26px;
  line-height: 32px;
  font-weight: 600;
}
h3,
.h3 {
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
}
h4,
.h4 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 600;
}
h5,
.h5 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}
h6,
.h6 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

@include media-breakpoint-up(md) {
  h1,
  .h1 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
  }
}
@include media-breakpoint-up(lg) {
  html,
  body {
    font-size: 16px;
    line-height: 24px;
  }
  a {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }
  h1,
  .h1 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
  }
  h2,
  .h2 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 600;
  }
  h3,
  .h3 {
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
  }
  h4,
  .h4 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
  }
  h5,
  .h5 {
    font-size: 22px;
    line-height: 27px;
    font-weight: 600;
  }
  h6,
  .h6 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }
}

@include media-breakpoint-up(xl) {
  html,
  body {
    font-size: 16px;
    line-height: 24px;
  }
  a {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
  h1,
  .h1 {
    font-size: 32px;
    line-height: 39px;
    font-weight: 600;
  }
  h2,
  .h2 {
    font-size: 26px;
    line-height: 34px;
    font-weight: 600;
  }
  h3,
  .h3 {
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
  }
  h4,
  .h4 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
  }
  h5,
  .h5 {
    font-size: 22px;
    line-height: 27px;
    font-weight: 600;
  }
  h6,
  .h6 {
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
  }
}

.text-muted {
  line-height: 1.25;
}
