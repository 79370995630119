@import "icons_functional_variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:   url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?valoen') format('truetype');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?valoen#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?valoen') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?valoen') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?valoen##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-home7 {
  &:before {
    content: $icon-home7; 
  }
}
.icon-pencil4 {
  &:before {
    content: $icon-pencil4; 
  }
}
.icon-cart {
  &:before {
    content: $icon-cart; 
  }
}
.icon-envelop {
  &:before {
    content: $icon-envelop; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-clock4 {
  &:before {
    content: $icon-clock4; 
  }
}
.icon-bell21 {
  &:before {
    content: $icon-bell21; 
  }
}
.icon-calendar2 {
  &:before {
    content: $icon-calendar2; 
  }
}
.icon-cog4 {
  &:before {
    content: $icon-cog4; 
  }
}
.icon-menu7 {
  &:before {
    content: $icon-menu7; 
  }
}
.icon-upload2 {
  &:before {
    content: $icon-upload2; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle; 
  }
}
.icon-cancel-circle2 {
  &:before {
    content: $icon-cancel-circle2; 
  }
}
.icon-checkmark3 {
  &:before {
    content: $icon-checkmark3; 
  }
}
.icon-arrow-up4 {
  &:before {
    content: $icon-arrow-up4; 
  }
}
.icon-arrow-right4 {
  &:before {
    content: $icon-arrow-right4; 
  }
}
.icon-arrow-down4 {
  &:before {
    content: $icon-arrow-down4; 
  }
}
.icon-arrow-left4 {
  &:before {
    content: $icon-arrow-left4; 
  }
}
.icon-circle-right4 {
  &:before {
    content: $icon-circle-right4; 
  }
}
.icon-circle-left4 {
  &:before {
    content: $icon-circle-left4; 
  }
}
.icon-checkmark-circle {
  &:before {
    content: $icon-checkmark-circle; 
  }
}
.icon-new-tab {
  &:before {
    content: $icon-new-tab; 
  }
}