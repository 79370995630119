@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

.hero,
.hero-landing {
  position: relative;
  height: 38.88889rem;
  padding: 6.66667rem;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .headingContent {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    overflow: hidden;
    position: relative;

    .headingTitle {
      div {
        &::before {
          content: attr(data-content);
          background: $brand-aok-green;
          box-decoration-break: clone;
          box-shadow: 0.55556rem 0 0 $brand-aok-green,
            -0.55556rem 0 0 $brand-aok-green;

          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            background: rgba(2, 68, 27, 0.65);
            box-shadow: 0.55556rem 0 0 rgba(2, 68, 27, 0.65),
              -0.55556rem 0 0 rgba(2, 68, 27, 0.65);
          }
          // mix-blend-mode: multiply;
          display: inline;
          margin: 0;
          color: #fff;

          font-weight: bold;
          line-height: 1.3;
        }
      }
    }

    .headingText {
      display: inline;
      background-color: rgba(255, 255, 255, 0.8);
      box-shadow: 0.55556rem 0px 0px rgba(255, 255, 255, 0.8),
        -0.55556rem 0px 0px rgba(255, 255, 255, 0.8);
      color: $brand-aok-green;
      line-height: 1.45;
      box-decoration-break: clone;
    }

    &::before {
      content: none;
    }

    .z100 {
      z-index: 100;
    }
  }
}

.hero {
  padding: 0px;
  justify-content: flex-end;
  height: 400px;
  font-size: 1.275rem;

  &:before {
    content: "";
    background-image: linear-gradient(187deg, #0000 45%, #000);
    display: block;
    height: 100%;
  }

  .headingTitleWrapper {
    padding: 20px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-bottom: -5px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: #005e3f;
      // mix-blend-mode: multiply;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background: rgba(2, 68, 27, 0.65);
        box-shadow: 0.55556rem 0 0 rgba(2, 68, 27, 0.65),
          -0.55556rem 0 0 rgba(2, 68, 27, 0.65);
      }
    }
  }

  .headingTitle {
    div {
      text-align: center;
      position: relative;

      &::before {
        font-weight: 700 !important;
        line-height: 1.25 !important;
        background: unset !important;
        // mix-blend-mode: hard-light !important;
        box-shadow: unset !important;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          background: none !important;

          box-shadow: none !important;
        }
      }

      &::after {
        content: "" !important;
        font-size: 1.33333rem !important;
        font-weight: 700 !important;
        line-height: 1.25 !important;
      }
    }
  }

  .headingText {
    text-align: center;
    width: 100%;
    display: inline-block !important;
    position: relative !important;
    margin: 0 !important;
    font-size: 0.88889rem !important;
    font-weight: 300 !important;
    line-height: 1.56 !important;
    background: transparent !important;
    color: #fff !important;
    box-shadow: none !important;
  }

  .headingContent {
    padding: 0px;
  }
}

@include media-breakpoint-up(md) {
  .hero {
    height: calc(100vh - 540px);
    font-size: 1.875rem;

    .headingTitleWrapper {
      padding: 50px;
    }
  }
}

.hero-flex-end {
  justify-content: flex-end;
}

.hero-landing {
  height: 100%;
  justify-content: center;

  @include media-breakpoint-down(md) {
    padding: 0px;
  }
}

.aok-hero-wrapper {

  .aok-hero-image-wrapper {
    height: 300px;
    position: relative;
    display: flex;
    flex-direction: row-reverse;

    @include media-breakpoint-up(md) {
      height: 400px;
    }
    @include media-breakpoint-up(lg) {
      height: 500px;
    }
    @include media-breakpoint-up(xl) {
      height: 600px;
    }

    .aok-hero-image {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center 30%;
      background-repeat: no-repeat;

      &:before {
        content: "";
        background-image: linear-gradient(187deg, #0000 45%, #000);
        display: block;
        height: 100%;
      }

    }

    .aok-hero-info {
      position: absolute;
      height: 40%;
      max-height: 175px;
      margin: 20px;
    }

    .aok-hero-image.img_FAMILY_BONUS {
      background-position: center 39%;
    }
  }

  @media (min-width: 1400px) {
    .lr-opacity {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: space-between;

      .opacity-start,
      .opacity-end {
        width: 9%;
        background-color: rgba(255, 255, 255, 0.65);
      }
    }
    .aok-hero-info {
      margin-right: 10% !important;
    }
  }
}

.headline-wrapper {
  background-color: #005e3f;
  position: relative;
  @include media-breakpoint-up(lg) {
    height: 220px;
  }

  h1 {
    color: #fff !important;
    position: relative;
    top: -28px;
    margin: 0 1.5rem;
    font-size: 2.5rem;
    line-height: 3rem;

    @include media-breakpoint-up(md) {
      top: -30px;
      font-size: 3rem;
      line-height: 3.75rem;
    }

    @include media-breakpoint-up(lg) {
      top: -40px;
      font-size: 4rem;
      line-height: 4.75rem;
      margin: 0;
    }
  }
}
