$icomoon-font-family: "aok-icons-2023";
$icomoon-font-path: "../assets/fonts";

$aok-icon-Abrechnung_rgb: "\e900";
$aok-icon-Abschlussarbeiten_rgb: "\e901";
$aok-icon-Allergiebettwaesche_rgb: "\e902";
$aok-icon-AOK_Bonusprogramm_rgb: "\e903";
$aok-icon-AOK_empfehlen_rgb: "\e904";
$aok-icon-AOK_Gesundheitskonto_rgb: "\e905";
$aok-icon-AOK_Praemienprogramm_rgb: "\e906";
$aok-icon-Apotheke_rgb: "\e907";
$aok-icon-Apps_rgb: "\e908";
$aok-icon-Arbeitslosenversicherung-_rgb: "\e909";
$aok-icon-Arzt_rgb: "\e90a";
$aok-icon-Arztsuche_rgb: "\e90b";
$aok-icon-Assistenzhund_rgb: "\e90c";
$aok-icon-Aufzug_rgb: "\e90d";
$aok-icon-Ausbildungsberufe_rgb: "\e90e";
$aok-icon-Auto_rgb: "\e90f";
$aok-icon-Baby_Bonus_rgb: "\e910";
$aok-icon-Baby_rgb: "\e911";
$aok-icon-Barrierefreiheit_rgb: "\e912";
$aok-icon-Batterie_laden_rgb: "\e913";
$aok-icon-Behandlung_beantragen_rgb: "\e914";
$aok-icon-Behandlung_beantragen_Zahn_rgb: "\e915";
$aok-icon-Behandlung_im_Ausland_rgb: "\e916";
$aok-icon-Behandlung_in_Europa_rgb: "\e917";
$aok-icon-Behandlung_planen_rgb: "\e918";
$aok-icon-Behandlung_planen_Zahn_rgb: "\e919";
$aok-icon-Behandlung_rgb: "\e91a";
$aok-icon-Beitraege_rgb: "\e91b";
$aok-icon-Benutzerhandbuch_rgb: "\e91c";
$aok-icon-Benutzerkonto_rgb: "\e91d";
$aok-icon-Berufserfahrene_rgb: "\e91e";
$aok-icon-Besprechungsraum_rgb: "\e91f";
$aok-icon-Besteck_rgb: "\e920";
$aok-icon-Bewerbung_rgb: "\e921";
$aok-icon-Beziehungen_rgb: "\e922";
$aok-icon-Blitz_rgb: "\e923";
$aok-icon-Briefumschlag_offen_rgb: "\e924";
$aok-icon-Briefumschlag_rgb: "\e925";
$aok-icon-Brille_rgb: "\e926";
$aok-icon-Brot_rgb: "\e927";
$aok-icon-Cafeteria_rgb: "\e928";
$aok-icon-Charity_rgb-path1: "\e929";
$aok-icon-Charity_rgb-path2: "\e92a";
$aok-icon-Chat_online_rgb: "\e92b";
$aok-icon-Chatbot_rgb: "\e92c";
$aok-icon-Check_rgb: "\e92d";
$aok-icon-Cloud_rgb: "\e92e";
$aok-icon-Co-Browsing_rgb: "\e92f";
$aok-icon-Code_rgb: "\e930";
$aok-icon-Computer_rgb: "\e931";
$aok-icon-Cookies_rgb: "\e932";
$aok-icon-Datei_rgb: "\e933";
$aok-icon-Dateien_rgb: "\e934";
$aok-icon-Datenaustausch_rgb: "\e935";
$aok-icon-Demenz_rgb: "\e936";
$aok-icon-Devices_rgb: "\e937";
$aok-icon-Diagramm_rgb: "\e938";
$aok-icon-Diamant_rgb: "\e939";
$aok-icon-Digitale_Gesundheitsanwendungen_rgb: "\e93a";
$aok-icon-Direkteinstieg_rgb: "\e93b";
$aok-icon-Dokument_rgb: "\e93c";
$aok-icon-Download_Folder_rgb: "\e93d";
$aok-icon-Download_rgb: "\e93e";
$aok-icon-Duales_Studium_rgb: "\e93f";
$aok-icon-Elektronische_Gesundheitskarte_rgb: "\e940";
$aok-icon-Elektronische_Patientenquittung_rgb: "\e941";
$aok-icon-Entspannung_rgb: "\e942";
$aok-icon-Ernaehrung_rgb: "\e943";
$aok-icon-Erstattung_rgb: "\e944";
$aok-icon-Fahrrad_rgb: "\e945";
$aok-icon-Fahrtkosten_rgb: "\e946";
$aok-icon-Familie_rgb: "\e947";
$aok-icon-Fernglas_rgb: "\e948";
$aok-icon-Feuer_rgb: "\e949";
$aok-icon-Fieberthermometer_rgb: "\e94a";
$aok-icon-Firmenkunden_rgb: "\e94b";
$aok-icon-Flagge_rgb: "\e94c";
$aok-icon-Folder_rgb: "\e94d";
$aok-icon-Formulare_rgb: "\e94e";
$aok-icon-Freiwillige_Versicherung_rgb: "\e94f";
$aok-icon-Frueherkennung_rgb: "\e950";
$aok-icon-Garderobe_rgb: "\e951";
$aok-icon-Gebaerdensprache_rgb: "\e952";
$aok-icon-Geburtsvorbereitungskurs_rgb: "\e953";
$aok-icon-Gedanken_rgb: "\e954";
$aok-icon-Geldbeutel_rgb: "\e955";
$aok-icon-Genehmigung_abwarten_rgb: "\e956";
$aok-icon-Geschaeftsstelle_online_rgb: "\e957";
$aok-icon-Geschaeftsstelle_rgb: "\e958";
$aok-icon-Geschenk_rgb: "\e959";
$aok-icon-Gesetz_rgb: "\e95a";
$aok-icon-Gesundheit_rgb: "\e95b";
$aok-icon-Gesundheitscheck_rgb: "\e95c";
$aok-icon-Gesundheitskurse_rgb: "\e95d";
$aok-icon-Gesunheitspartner_rgb: "\e95e";
$aok-icon-Gluehbirne_rgb: "\e95f";
$aok-icon-Haeusliche_Krankenpflege_rgb: "\e960";
$aok-icon-Hand_Daumen_hoch_rgb: "\e961";
$aok-icon-Hand_Daumen_neutral_rgb: "\e962";
$aok-icon-Hand_Daumen_runter_rgb: "\e963";
$aok-icon-Hand_stopp_rgb: "\e964";
$aok-icon-Hand_zeigen_rgb: "\e965";
$aok-icon-Happy_rgb: "\e966";
$aok-icon-Hausarztprogramm_rgb: "\e967";
$aok-icon-Haushaltshilfe_rgb: "\e968";
$aok-icon-Hautirritationen_rgb: "\e969";
$aok-icon-Hebamme_rgb: "\e96a";
$aok-icon-Herz_rgb: "\e96b";
$aok-icon-Hoerbeeintraechtigung_rgb: "\e96c";
$aok-icon-Hygiene_Armbeuge_rgb: "\e96d";
$aok-icon-Hygiene_Desinfektion_rgb: "\e96e";
$aok-icon-Hygiene_Maske_rgb: "\e96f";
$aok-icon-Impfung_rgb: "\e970";
$aok-icon-Initiativbewerbung_rgb: "\e971";
$aok-icon-Integrierte_Versorgung_rgb: "\e972";
$aok-icon-Jolinchen_rgb: "\e973";
$aok-icon-Jugendliche_rgb: "\e974";
$aok-icon-Kaefer_rgb: "\e975";
$aok-icon-Kalender_Check_rgb: "\e976";
$aok-icon-Kalender_mit_Datum_rgb: "\e977";
$aok-icon-Kalender_mit_Pfeil_rgb: "\e978";
$aok-icon-Kalender_rgb: "\e979";
$aok-icon-Karte_rgb: "\e97a";
$aok-icon-Kind_rgb: "\e97b";
$aok-icon-Kinderkrankengeld_rgb: "\e97c";
$aok-icon-Knochen_rgb: "\e97d";
$aok-icon-Kochmtze_rgb: "\e97e";
$aok-icon-Kompass_rgb: "\e97f";
$aok-icon-Kopfhoerer_rgb: "\e980";
$aok-icon-Krankengeld_rgb: "\e981";
$aok-icon-Krankenhaus_rgb: "\e982";
$aok-icon-Krankenhausbehandlung_rgb: "\e983";
$aok-icon-Krankentransport_rgb: "\e984";
$aok-icon-Krankenversicherung_rgb: "\e985";
$aok-icon-Krankmeldung_rgb: "\e986";
$aok-icon-Kuendigungsformular_rgb: "\e987";
$aok-icon-Kundenzufriedenheit_rgb: "\e988";
$aok-icon-Kur_rgb: "\e989";
$aok-icon-Lautstaerke_rgb: "\e98a";
$aok-icon-Leichte_Sprache_rgb: "\e98b";
$aok-icon-Lesezeichen_rgb: "\e98c";
$aok-icon-Lippenstift_Beauty_rgb: "\e98d";
$aok-icon-Location_rgb: "\e98e";
$aok-icon-Luftballon_rgb: "\e98f";
$aok-icon-Lunge_Atmungssystem_rgb: "\e990";
$aok-icon-Magazin_rgb: "\e991";
$aok-icon-Medaille_rgb: "\e992";
$aok-icon-Mediale_Angebote_rgb: "\e993";
$aok-icon-Medikamente_rgb: "\e994";
$aok-icon-Medizin-und-Versorgung_rgb: "\e995";
$aok-icon-Megafon_rgb: "\e996";
$aok-icon-Mikrofon_rgb: "\e997";
$aok-icon-Mitglied-werden_rgb: "\e998";
$aok-icon-Mond_rgb: "\e999";
$aok-icon-Mund_rgb: "\e99a";
$aok-icon-Mutterschaftsgeld_rgb: "\e99b";
$aok-icon-Nachhaltigkeit_rgb: "\e99c";
$aok-icon-Netzwerk_Zusammenhaenge_rgb: "\e99d";
$aok-icon-Newsletter_rgb: "\e99e";
$aok-icon-Nummer-10_rgb: "\e99f";
$aok-icon-Nummer_0_rgb: "\e9a0";
$aok-icon-Nummer_1_rgb: "\e9a1";
$aok-icon-Nummer_2_rgb: "\e9a2";
$aok-icon-Nummer_3_rgb: "\e9a3";
$aok-icon-Nummer_4_rgb: "\e9a4";
$aok-icon-Nummer_5_rgb: "\e9a5";
$aok-icon-Nummer_6_rgb: "\e9a6";
$aok-icon-Nummer_7_rgb: "\e9a7";
$aok-icon-Nummer_8_rgb: "\e9a8";
$aok-icon-Nummer_9_rgb: "\e9a9";
$aok-icon-Online_Kurse_rgb: "\e9aa";
$aok-icon-Osteopathie_rgb: "\e9ab";
$aok-icon-Parkplatz_rgb: "\e9ac";
$aok-icon-Persoenliche_Beratung_rgb: "\e9ad";
$aok-icon-Personenwaage_rgb: "\e9ae";
$aok-icon-Pfeil_links_oben_rgb: "\e9af";
$aok-icon-Pfeil_links_rgb: "\e9b0";
$aok-icon-Pfeil_links_unten_rgb: "\e9b1";
$aok-icon-Pfeil_oben_rgb: "\e9b2";
$aok-icon-Pfeil_rechts_oben_rgb: "\e9b3";
$aok-icon-Pfeil_rechts_rgb: "\e9b4";
$aok-icon-Pfeil_rechts_unten_rgb: "\e9b5";
$aok-icon-Pfeil_unten_rgb: "\e9b6";
$aok-icon-Pflaster_rgb: "\e9b7";
$aok-icon-Pflege_rgb: "\e9b8";
$aok-icon-Pflegeversicherung_rgb: "\e9b9";
$aok-icon-Physiotherapie_rgb: "\e9ba";
$aok-icon-Plakat_Aussenwerbung_rgb: "\e9bb";
$aok-icon-Plus_rgb: "\e9bc";
$aok-icon-Podcast_rgb: "\e9bd";
$aok-icon-Pokal_rgb: "\e9be";
$aok-icon-Professionelle_Zahnreinigung_rgb: "\e9bf";
$aok-icon-Rakete_Fortschritt_rgb: "\e9c0";
$aok-icon-Rechte_als_Patient_rgb: "\e9c1";
$aok-icon-Rechtsdatenbank_rgb: "\e9c2";
$aok-icon-Recycling_rgb: "\e9c3";
$aok-icon-Regenschirm_rgb: "\e9c4";
$aok-icon-Reha_rgb: "\e9c5";
$aok-icon-Reise_rgb: "\e9c6";
$aok-icon-Rettungsring_Nothilfe_rgb: "\e9c7";
$aok-icon-Rollstuhl_rgb: "\e9c8";
$aok-icon-Schluessel_rgb: "\e9c9";
$aok-icon-Schueler_innen_rgb: "\e9ca";
$aok-icon-Schwangerschaft_rgb: "\e9cb";
$aok-icon-Sehbeeintraechtigung_rgb: "\e9cc";
$aok-icon-Selbsthilfefoerderung_rgb: "\e9cd";
$aok-icon-Selfservice_rgb: "\e9ce";
$aok-icon-Seminar_online_rgb: "\e9cf";
$aok-icon-Seminar_rgb: "\e9d0";
$aok-icon-Smartphone_rgb: "\e9d1";
$aok-icon-Sonne_rgb: "\e9d2";
$aok-icon-Sparschwein_rgb: "\e9d3";
$aok-icon-Sport_rgb: "\e9d4";
$aok-icon-Sprechblase_mit_Ausrufezeichen_rgb: "\e9d5";
$aok-icon-Sprechblase_mit_Fragezeichen_rgb: "\e9d6";
$aok-icon-Sprechblase_mit_Punkten_rgb: "\e9d7";
$aok-icon-Sprechblase_mit_Text_rgb: "\e9d8";
$aok-icon-Sprechblase_rgb: "\e9d9";
$aok-icon-Sprechblasen_rgb: "\e9da";
$aok-icon-Stadt_rgb: "\e9db";
$aok-icon-Stellenangebote_rgb: "\e9dc";
$aok-icon-Stern_rgb: "\e9dd";
$aok-icon-Stoffwechsel_rgb: "\e9de";
$aok-icon-Stress_rgb: "\e9df";
$aok-icon-Stressbewaeltigung_rgb: "\e9e0";
$aok-icon-Studierende_rgb: "\e9e1";
$aok-icon-Suche_nach_Leistungserbringern_rgb: "\e9e2";
$aok-icon-Suche_rgb: "\e9e3";
$aok-icon-Synapsen_rgb: "\e9e4";
$aok-icon-Tacho_rgb: "\e9e5";
$aok-icon-Tarife_rgb: "\e9e6";
$aok-icon-Telefon_mit_Pfeil_rgb: "\e9e7";
$aok-icon-Telefon_mit_Schallwellen_rgb: "\e9e8";
$aok-icon-Telefon_mit_Sprechblase_rgb: "\e9e9";
$aok-icon-Telefon_rgb: "\e9ea";
$aok-icon-Trauben_rgb: "\e9eb";
$aok-icon-Traurig_rgb: "\e9ec";
$aok-icon-Treppenhaus_rgb: "\e9ed";
$aok-icon-Tropfen_rgb: "\e9ee";
$aok-icon-Uhr_rgb: "\e9ef";
$aok-icon-Unfallversicherung_rgb: "\e9f0";
$aok-icon-Upload_Folder_rgb: "\e9f1";
$aok-icon-Upload_rgb: "\e9f2";
$aok-icon-Verdauung_rgb: "\e9f3";
$aok-icon-Versicherung_in_Europa_rgb: "\e9f4";
$aok-icon-Versicherung_rgb: "\e9f5";
$aok-icon-Video_rgb: "\e9f6";
$aok-icon-Vielfalt_rgb: "\e9f7";
$aok-icon-Vorsorge_rgb: "\e9f8";
$aok-icon-Wahltarife_rgb: "\e9f9";
$aok-icon-Wartebereich_rgb: "\e9fa";
$aok-icon-WC_behindertengerecht_rgb: "\e9fb";
$aok-icon-WC_beide_rgb: "\e9fc";
$aok-icon-WC_Damen_rgb: "\e9fd";
$aok-icon-WC_genderneutral_1_rgb: "\e9fe";
$aok-icon-WC_genderneutral_2_rgb: "\e9ff";
$aok-icon-WC_Herren_rgb: "\ea00";
$aok-icon-Wegweiser_rgb: "\ea01";
$aok-icon-Weltkugel_rgb: "\ea02";
$aok-icon-Wetter_rgb: "\ea03";
$aok-icon-Wickelraum_rgb: "\ea04";
$aok-icon-Wind_rgb: "\ea05";
$aok-icon-Wohlbefinden_rgb: "\ea06";
$aok-icon-Wohnzimmer_rgb: "\ea07";
$aok-icon-Wolke_rgb: "\ea08";
$aok-icon-Yoga_rgb: "\ea09";
$aok-icon-Zahnmedizin_rgb: "\ea0a";
$aok-icon-Zahnrad_rgb: "\ea0b";
$aok-icon-Zeitraum_rgb: "\ea0c";
$aok-icon-Zeitung_rgb: "\ea0d";
$aok-icon-Ziel_rgb: "\ea0e";
$aok-icon-Zusatzversicherung_rgb: "\ea0f";
$aok-icon-Zuzahlung_rgb: "\ea10";
$aok-icon-Zweitmeinung_rgb: "\ea11";

