@import "bootstrap_overrides/variables";

.programm-details {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  .participants:not(:first-of-type) {
    margin-top: 1rem;
  }
  // h2 {
  //     margin-bottom: -0.8rem;
  // }
  h3 {
    font-weight: 500;
  }
}

.programm-details-accordion-card {
  box-shadow: rgba(223, 227, 230, 1) 0px 2px 0px 0px !important;

  &:not(:first-child) {
    margin-bottom: 3rem;
  }
}
