@import "~bootstrap/scss/_functions.scss";
@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/_mixins.scss";

.progress-stepper {
  &.nav-pills {
    .nav-item {
      padding: 0 0;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        &:nth-child(odd) {
          padding-bottom: 10px;
          padding-right: 10px;
        }
      }
      @include media-breakpoint-up(lg) {
        &:not(:last-child) {
          padding-bottom: 0;
          padding-right: 10px;
        }
      }
    }
    .nav-link {
      border-radius: 0;
      text-decoration: none;
      display: flex;

      flex-direction: row;
      box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 0px 1px;
      min-height: 60px;
      min-width: 190px;
      background: #fff;
      color: #303030;
      align-items: center;
      border-top: 6px solid $primary;

      p:first-of-type {
        font-weight: bold;
        margin-right: 0.5rem;
      }
      p {
        margin: 0;
      }
      @include media-breakpoint-up(md) {
        flex-direction: column;
        min-height: 75px;
        align-items: flex-start;
      }
      &:hover {
        background-color: #DFE3E6;
      }

      &.disabled {
        opacity: 0.65;
      }
    }

    .nav-link.active,
    .show > .nav-link {
      background-color: #6D767C;
      border-color: transparent;
      color: #fff;
      cursor: default;
      border-top: 6px solid #40484D;

      @include media-breakpoint-up(md) {
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          text-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.13);
          margin-left: -9px;
          width: 0;
          height: 0;
          border-top: solid 9px #6D767C;
          border-left: solid 9px transparent;
          border-right: solid 9px transparent;
        }
      }
    }
  }
}
