@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 320px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 310px,
  md: 748px,
  lg: 1004px,
  xl: 1200px,
);

// @media (max-width: map-get($grid-breakpoints, lg)) {
//   .row > .col,
//   .row > [class*="col-"] {
//     padding-right: 10px;
//     padding-left: 10px;
//   }
// }

// @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
//   .row > .col,
//   .row > [class*="col-"] {
//     padding-right: 20px;
//     padding-left: 20px;
//     padding-top: 20px;
//     padding-bottom: 20px;
//   }
// }

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// $table-cell-padding:          .75rem !default;
// $table-cell-padding-sm:       .3rem !default;

//$table-color: #303030;
// $table-bg:                    null !default;
// $table-accent-bg:             rgba($black, .05) !default;
// $table-hover-color:           $table-color !default;
// $table-hover-bg:              rgba($black, .075) !default;
// $table-active-bg:             $table-hover-bg !default;

$table-border-width: 3px;
$table-border-color: #fff;

$table-head-bg: #dfe3e6;
$table-head-color: #000;
// $table-th-font-weight:        null !default;

// $table-dark-color:            $white !default;
// $table-dark-bg:               $gray-800 !default;
// $table-dark-accent-bg:        rgba($white, .05) !default;
// $table-dark-hover-color:      $table-dark-color !default;
// $table-dark-hover-bg:         rgba($white, .075) !default;
// $table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;

// $table-striped-order:         odd !default;

// $table-caption-color:         $text-muted !default;

// $table-bg-level:              -9 !default;
// $table-border-level:          -6 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: AOKBuenosAiresText, Arial, "sans-serif";

$font-size-base: 1rem;
$line-height-base: 1.4375;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.556;
$h3-font-size: $font-size-base * 1.444;
$h4-font-size: $font-size-base * 1.333;
$h5-font-size: $font-size-base * 1.222;
$h6-font-size: $font-size-base * 1.111;

$headings-font-weight: 300;
$headings-line-height: 1.214;
$headings-margin-bottom: $spacer;

// Color system

$brand-aok-green: #005e3f; //Primary
$brand-aok-orange: #ff9900; //Primary

$primary: $brand-aok-green;

// Body
//
// Settings for the `<body>` element.

$body-color: #293033;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.75rem;
$alert-padding-x: 0.75rem;
$alert-margin-bottom: 0;
$alert-border-radius: 0;

$alert-bg-level: 1;
$alert-border-level: 1;

// Breadcrumbs

$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: #fff;
$breadcrumb-divider-color: #6d767c;
$breadcrumb-active-color: #6d767c;
$breadcrumb-divider: quote("»");
$breadcrumb-border-radius: 0;
$breadcrumb-item-padding: 1rem;
$breadcrumb-font-size: .875rem;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: 10px;
$btn-padding-x: 20px;
// $btn-font-family:             $input-btn-font-family !default;
// $btn-font-size:               $input-btn-font-size !default;
// $btn-line-height:             $input-btn-line-height !default;
// $btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

// $btn-padding-y-sm:            $input-btn-padding-y-sm !default;
// $btn-padding-x-sm:            $input-btn-padding-x-sm !default;
// $btn-font-size-sm:            $input-btn-font-size-sm !default;
// $btn-line-height-sm:          $input-btn-line-height-sm !default;

// $btn-padding-y-lg:            $input-btn-padding-y-lg !default;
// $btn-padding-x-lg:            $input-btn-padding-x-lg !default;
// $btn-font-size-lg:            $input-btn-font-size-lg !default;
// $btn-line-height-lg:          $input-btn-line-height-lg !default;

// $btn-border-width:            $input-btn-border-width !default;

// $btn-font-weight:             $font-weight-normal !default;
// $btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
// $btn-focus-width:             $input-btn-focus-width !default;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 1;
// $btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

// $btn-link-disabled-color:     $gray-600 !default;

// $btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-x-lg: 1.25rem;
$input-btn-font-size-lg: 1.125rem;
$input-btn-focus-box-shadow: none;

$input-btn-padding-y: 0.3333333333333333rem;
$input-btn-padding-x: 0.6666666666666666rem;

$input-btn-padding-y: 0.3333333333333333rem;
$input-btn-padding-x: 0.6666666666666666rem;

$btn-focus-box-shadow: none;

$input-btn-focus-box-shadow: none;
$input-border-color: #c1c1be;
$input-focus-border-color: $primary;

// Forms

$custom-control-indicator-active-bg: $primary;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
// $input-font-family:                     $input-btn-font-family !default;
// $input-font-size:                       $input-btn-font-size !default;
// $input-font-weight:                     $font-weight-base !default;
// $input-line-height:                     $input-btn-line-height !default;

// $input-padding-y-sm:                    $input-btn-padding-y-sm !default;
// $input-padding-x-sm:                    $input-btn-padding-x-sm !default;
// $input-font-size-sm:                    $input-btn-font-size-sm !default;
// $input-line-height-sm:                  $input-btn-line-height-sm !default;

// $input-padding-y-lg:                    $input-btn-padding-y-lg !default;
// $input-padding-x-lg:                    $input-btn-padding-x-lg !default;
// $input-font-size-lg:                    $input-btn-font-size-lg !default;
// $input-line-height-lg:                  $input-btn-line-height-lg !default;

// $input-bg:                              $white !default;
$input-disabled-bg: #dfe3e6;

// $input-color:                           $gray-700 !default;
// $input-border-color:                    $gray-400 !default;
// $input-border-width:                    $input-btn-border-width !default;
$input-box-shadow: none;

$input-border-radius: 0;
// $input-border-radius-lg:                $border-radius-lg !default;
// $input-border-radius-sm:                $border-radius-sm !default;

// $input-focus-bg:                        $input-bg !default;
// $input-focus-border-color:              lighten($component-active-bg, 25%) !default;
// $input-focus-color:                     $input-color !default;
// $input-focus-width:                     $input-btn-focus-width !default;
// $input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

// $input-placeholder-color:               $gray-600 !default;
// $input-plaintext-color:                 $body-color !default;

// $input-height-border:                   $input-border-width * 2 !default;

// $input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
// $input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
// $input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y / 2) !default;

$input-height: 48px;
// $input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
// $input-height-lg:                       add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

// $input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
