@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "icons_functional_variables";
@import "icons_functional_aok_variables";

// Standalone LINK
a.link {
  color: $primary;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: #18ab42;
  }

  &.link-internal {
    &::after {
      display: inline !important;
      font-family: "aok-icons-functional-2023";
      content: $aok-icon-functional-Arrow-Rounded-Right;
      font-weight: 300;
      margin-left: 5px;
      vertical-align: middle;
    }
  }

  &.link-external {
    &::after {
      display: inline !important;
      font-family: aok-icons-functional;
      content: \00a0$icon-new-tab;
    }
  }
}

.page-link {
  text-decoration: none;
}
.impressum-link {
  cursor: pointer;
}

.link-underline {
  background-image: linear-gradient(#6d767c, #6d767c);
  transition: background-size 0.35s ease-in-out;
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 0 1px;
}

.link-underline {
  &:hover {
    background-size: 100% 1px;
  }
}