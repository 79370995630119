.alert-danger {
  color: #fff;
  background-color: #FF4358;
  border-color: #FF4358;
}

.alert-success {
  color: #fff;
  background-color: #47BE73;
  border-color: #47BE73;
}

.alert-warning {
  color: #fff;
  background-color: #FF9900;
  border-color: #FF9900;
}

.alert-info {
  color: #fff;
  background-color: #1595a9;
  border-color: #1595a9;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 0px 0 #000;
  opacity: .5;
  text-decoration: none;
  cursor: pointer;

}

.alert-danger,.alert-warning,.alert-success,.alert-info{
  svg{
	display: none;
  }
}
