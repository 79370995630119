@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "icons_functional_variables";
@import "icons_aok_variables";

.form-header {
  position: relative;
  .form-icon {
    background-color: #005e3f;
    color: #fff;
    height: 36px;
    left: 0;
    position: absolute;
    top: -30px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      display: inline-block;
      font: 14.54545px/1 aok-icons-functional;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: translate(0, 0);
      content: $icon-pencil4;
      vertical-align: middle;
      position: absolute;
      margin-top: 5px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:after {
      border-top: 10px solid #005e3f;
      border-right: 18px solid transparent;
      border-left: 18px solid transparent;
      content: "";
      left: 0;
      position: absolute;
      top: 36px;
    }
  }
}

.form-wrapper {
  background: #f8f5e3;
  padding: 10px;
  @include media-breakpoint-up(lg) {
    margin-top: 20px;
    padding: 0;
  }
  .form-col {
    @include media-breakpoint-up(lg) {
      padding: 2rem;
    }
    &:nth-child(1) {
      background-color: #f8f5e3;
      padding: 1rem;
      @include media-breakpoint-up(lg) {
        padding: 2rem;
      }
    }
    &:nth-child(2) {
      background-color: #f8f5e3;
      padding: 1rem;
      @include media-breakpoint-up(lg) {
        padding: 2rem;
      }
    }
  }
  legend {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 0;
  }
  .btn-delete {
    margin-top: 30px;
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }
}
// @include media-breakpoint-up(md) {
.form-wrapper.has-symbol {
  position: relative;
  &::after {
    font-family: "aok-icons-2023";
    content: $aok-icon-Formulare_rgb;
    font-weight: 300;
    font-size: 3rem;
    color: $primary;
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      font-size: 13rem;
      right: 2rem;
      top: 8rem;
    }
  }
}
// }

.col-form-label {
  font-weight: 400;
  line-height: 1;
  @include media-breakpoint-up(lg) {
    text-align: right;
    line-height: 35px;
  }
}

.lieferadresse {
  background: #fff;
  padding: 10px 0px;
  margin: 0px;
  margin-bottom: 20px;
  
 
  label {
    cursor: pointer;
  }

  .lieferadresse-radio {
    margin-right: 1rem;
  }

  .lieferadresse-header {
    width: 100%;
    cursor: pointer;
  }

  .liferadress-text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    margin: 0px;
    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }
}

.password-help {
  font-size: smaller;
  font-weight: 400;
  margin: 1rem !important;
  background-color: rgba(0, 0, 0, 0.03);
  ul {
    > li {
      list-style-type: none;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
