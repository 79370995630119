@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "icons_functional_aok_variables";
//
// Accordion
//
.accordion {
  overflow-anchor: none;
  .card {
    overflow: hidden;
    border: 0;
    @include border-radius(0);
    box-shadow: #dfe3e6 0px 2px 0px 0px;
    &:not(:last-of-type) {
      @include border-bottom-radius(0);
      margin-bottom: 0.25rem;
    }
    &:not(:first-of-type) {
      @include border-top-radius(0);
    }
    > .card-header {
      @include border-radius(0);
      margin-bottom: 0;
      padding: 0;
      background-color: #fff;
      border: 0;
      .btn {
        color: #40484d;
        padding: 16px;
        border-radius: 0;
        height: auto;
        > .btn_content {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &:focus {
          box-shadow: inset 0 0 0 2px #000;
          /* keyboard-only focus styles */
        }
        @include hover-focus {
          color: #18ab42;
          text-decoration: none;
        }
        span[class*="icon-"] {
          color: #18ab42;

          &:before {
            content: $aok-icon-functional-Minus;
          }

          &:hover {
            color: #18ab42;
          }
        }
        &.collapsed {
          span[class*="icon-"] {
            color: #40484d;
            &:before {
              content: $aok-icon-functional-Plus;
            }

            &:hover {
              color: #18ab42;
              &:before {
                content: $aok-icon-functional-Minus;
              }
            }
          }
        }
      }
    }
    .card-body {
      font-weight: 200;
      padding: 0 16px 16px;
      @include media-breakpoint-up(md) {
        padding: 16px;
      }
    }
  }
  &.accordion-mitsammler {
    .card {
      box-shadow: none;
      > .card-header {
        background-color: #f2f4f4;
      }
    }
  }
}
