@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

.online-scheckhefte-wrapper {
  padding: 0px 17px;

  .online-scheckhefte-items {
    padding: 10px;
    background: #F8F5E3;
    padding-left: 25px;

    @include media-breakpoint-down(sm) {
      padding-right: 25px;
    }
  }

  .online-scheckhefte-name {
    float: left;
    min-width: 125px;
  }
  .online-scheckhefte-status {
    float: left;
    font-size: 14px;
  }
}

.kat-tip_icon {
  margin-right: 20px;
}

.description-point {
  float: left;
  font-size: 20px;
  width: 100%;
}

.upload-box {
  background-color: #DFE3E6;
  position: relative;
  padding: 100px 20px;
  border: 2px dashed #AFB6BC;

  margin: 0px;
  width: 100%;

  &.has-error {
    border: 2px dashed #dc3545;
    label {
      color: #dc3545;
    }
  }

  &.highlight {
    background-color: #E4E5E5;
  }

  .box-dragndrop {
    display: inline; 
  }

  .box-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      text-align: center;
      border-color: transparent;
      background-color: transparent;
    }
  }

  .box-button {
    font-weight: 700;
    color: #e5edf1;
    background-color: #008a34;
    display: none;
    padding: 8px 16px;
    margin: 40px auto 0;
  }

  .box-icon {
    width: 100%;
    display: block;
    margin-bottom: 40px;
    text-align: center;
    font-size: 2rem;
    
    .icon {
      font-weight: bold;
      font-size: 3rem;
    }
  }
}

.upload-list {
  padding: 0px;
}

.upload-list-item {
  padding: 14px 9px;
  background-color: #fff;
}

