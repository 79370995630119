@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

footer {
  background-color: #004730;

  @include media-breakpoint-down(md) {
    padding-bottom: 70px;
  }

  .footer-pagelink {
    padding: 1.25rem 0;
    border-bottom: solid 1px #c3c3c3;
  }

  .footer-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px 20px;

    .footer-nav_category {
      flex: 1 0 auto;
      @include media-breakpoint-down(sm) {
        &:last-of-type {
          margin: 20px 0 20px;
        }
      }
      .footer-headline {
        display: block;
        font-family: "AOKBuenosAires";
        color: #50eb5d;
        font-weight: 600 !important;
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
        margin: 10px 0 20px;

        @include media-breakpoint-up(lg) {
          font-size: 22px;
          line-height: 27px;
          margin: 30px 0 20px;
        }
      }

      > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          line-height: 32px;
          a {
            color: #fff;
            font-weight: 600 !important;
            text-decoration: none;

            @include media-breakpoint-up(lg) {
              font-size: 16px;
            }
            &:hover {
              text-decoration: underline;
            }
            
          }
        }
      }
    }
  }
}
