@import "icons_functional_aok_variables";

@font-face {
  font-family: '#{$icomoon-font-family2}'; 
  src:  url('#{$icomoon-font-path2}/#{$icomoon-font-family2}.eot?75elj8');
  src:  url('#{$icomoon-font-path2}/#{$icomoon-font-family2}.eot?75elj8#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path2}/#{$icomoon-font-family2}.ttf?75elj8') format('truetype'),
    url('#{$icomoon-font-path2}/#{$icomoon-font-family2}.woff?75elj8') format('woff'),
    url('#{$icomoon-font-path2}/#{$icomoon-font-family2}.svg?75elj8##{$icomoon-font-family2}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="aok-icon-functional"], [class*=" aok-icon-functional"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family2}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aok-icon-functional-Adress-Book {
  &:before {
    content: $aok-icon-functional-Adress-Book; 
  }
}
.aok-icon-functional-Alert {
  &:before {
    content: $aok-icon-functional-Alert; 
  }
}
.aok-icon-functional-Alert-Circle-Filled {
  &:before {
    content: $aok-icon-functional-Alert-Circle-Filled; 
  }
}
.aok-icon-functional-Android {
  &:before {
    content: $aok-icon-functional-Android; 
  }
}
.aok-icon-functional-Apple {
  &:before {
    content: $aok-icon-functional-Apple; 
  }
}
.aok-icon-functional-Arrow-Down {
  &:before {
    content: $aok-icon-functional-Arrow-Down; 
  }
}
.aok-icon-functional-Arrow-Left {
  &:before {
    content: $aok-icon-functional-Arrow-Left; 
  }
}
.aok-icon-functional-Arrow-Left-Circle-Filled {
  &:before {
    content: $aok-icon-functional-Arrow-Left-Circle-Filled; 
  }
}
.aok-icon-functional-Arrow-Left-Outline {
  &:before {
    content: $aok-icon-functional-Arrow-Left-Outline; 
  }
}
.aok-icon-functional-Arrow-Right {
  &:before {
    content: $aok-icon-functional-Arrow-Right; 
  }
}
.aok-icon-functional-Arrow-Right-Circle {
  &:before {
    content: $aok-icon-functional-Arrow-Right-Circle; 
  }
}
.aok-icon-functional-Arrow-Right-Circle-Filled {
  &:before {
    content: $aok-icon-functional-Arrow-Right-Circle-Filled; 
  }
}
.aok-icon-functional-Arrow-Rounded-Down {
  &:before {
    content: $aok-icon-functional-Arrow-Rounded-Down; 
  }
}
.aok-icon-functional-Arrow-Rounded-Left {
  &:before {
    content: $aok-icon-functional-Arrow-Rounded-Left; 
  }
}
.aok-icon-functional-Arrow-Rounded-Leftdown {
  &:before {
    content: $aok-icon-functional-Arrow-Rounded-Leftdown; 
  }
}
.aok-icon-functional-Arrow-Rounded-Leftup {
  &:before {
    content: $aok-icon-functional-Arrow-Rounded-Leftup; 
  }
}
.aok-icon-functional-Arrow-Rounded-Right {
  &:before {
    content: $aok-icon-functional-Arrow-Rounded-Right; 
  }
}
.aok-icon-functional-Arrow-Rounded-Rightdown {
  &:before {
    content: $aok-icon-functional-Arrow-Rounded-Rightdown; 
  }
}
.aok-icon-functional-Arrow-Rounded-Rightup {
  &:before {
    content: $aok-icon-functional-Arrow-Rounded-Rightup; 
  }
}
.aok-icon-functional-Arrow-Rounded-Up {
  &:before {
    content: $aok-icon-functional-Arrow-Rounded-Up; 
  }
}
.aok-icon-functional-Arrow-Small-Down {
  &:before {
    content: $aok-icon-functional-Arrow-Small-Down; 
  }
}
.aok-icon-functional-Arrow-Small-Left {
  &:before {
    content: $aok-icon-functional-Arrow-Small-Left; 
  }
}
.aok-icon-functional-Arrow-Small-Right {
  &:before {
    content: $aok-icon-functional-Arrow-Small-Right; 
  }
}
.aok-icon-functional-Arrow-Small-Up {
  &:before {
    content: $aok-icon-functional-Arrow-Small-Up; 
  }
}
.aok-icon-functional-Arrow-Up {
  &:before {
    content: $aok-icon-functional-Arrow-Up; 
  }
}
.aok-icon-functional-Audio {
  &:before {
    content: $aok-icon-functional-Audio; 
  }
}
.aok-icon-functional-Backwards {
  &:before {
    content: $aok-icon-functional-Backwards; 
  }
}
.aok-icon-functional-Become-member {
  &:before {
    content: $aok-icon-functional-Become-member; 
  }
}
.aok-icon-functional-Bookmark {
  &:before {
    content: $aok-icon-functional-Bookmark; 
  }
}
.aok-icon-functional-Bookmark-Filled {
  &:before {
    content: $aok-icon-functional-Bookmark-Filled; 
  }
}
.aok-icon-functional-Bookmarks-List {
  &:before {
    content: $aok-icon-functional-Bookmarks-List; 
  }
}
.aok-icon-functional-Calendar {
  &:before {
    content: $aok-icon-functional-Calendar; 
  }
}
.aok-icon-functional-Calendar-Check {
  &:before {
    content: $aok-icon-functional-Calendar-Check; 
  }
}
.aok-icon-functional-Cancel-Circle {
  &:before {
    content: $aok-icon-functional-Cancel-Circle; 
  }
}
.aok-icon-functional-Cancel-Circle-Filled {
  &:before {
    content: $aok-icon-functional-Cancel-Circle-Filled; 
  }
}
.aok-icon-functional-Caret-Down {
  &:before {
    content: $aok-icon-functional-Caret-Down; 
  }
}
.aok-icon-functional-Caret-Left {
  &:before {
    content: $aok-icon-functional-Caret-Left; 
  }
}
.aok-icon-functional-Caret-Right {
  &:before {
    content: $aok-icon-functional-Caret-Right; 
  }
}
.aok-icon-functional-Caret-Select {
  &:before {
    content: $aok-icon-functional-Caret-Select; 
  }
}
.aok-icon-functional-Caret-Up {
  &:before {
    content: $aok-icon-functional-Caret-Up; 
  }
}
.aok-icon-functional-Chat {
  &:before {
    content: $aok-icon-functional-Chat; 
  }
}
.aok-icon-functional-Checkmark {
  &:before {
    content: $aok-icon-functional-Checkmark; 
  }
}
.aok-icon-functional-Checkmark-Circle {
  &:before {
    content: $aok-icon-functional-Checkmark-Circle; 
  }
}
.aok-icon-functional-Checkmark-Circle-Filled {
  &:before {
    content: $aok-icon-functional-Checkmark-Circle-Filled; 
  }
}
.aok-icon-functional-Clipboard {
  &:before {
    content: $aok-icon-functional-Clipboard; 
  }
}
.aok-icon-functional-Clock {
  &:before {
    content: $aok-icon-functional-Clock; 
  }
}
.aok-icon-functional-Close {
  &:before {
    content: $aok-icon-functional-Close; 
  }
}
.aok-icon-functional-Close-small {
  &:before {
    content: $aok-icon-functional-Close-small; 
  }
}
.aok-icon-functional-Compare {
  &:before {
    content: $aok-icon-functional-Compare; 
  }
}
.aok-icon-functional-Copy {
  &:before {
    content: $aok-icon-functional-Copy; 
  }
}
.aok-icon-functional-Delete {
  &:before {
    content: $aok-icon-functional-Delete; 
  }
}
.aok-icon-functional-Documents {
  &:before {
    content: $aok-icon-functional-Documents; 
  }
}
.aok-icon-functional-Download {
  &:before {
    content: $aok-icon-functional-Download; 
  }
}
.aok-icon-functional-Download-Circle-Filled {
  &:before {
    content: $aok-icon-functional-Download-Circle-Filled; 
  }
}
.aok-icon-functional-Download-Folder {
  &:before {
    content: $aok-icon-functional-Download-Folder; 
  }
}
.aok-icon-functional-Enlarge {
  &:before {
    content: $aok-icon-functional-Enlarge; 
  }
}
.aok-icon-functional-Enter {
  &:before {
    content: $aok-icon-functional-Enter; 
  }
}
.aok-icon-functional-Equalizer {
  &:before {
    content: $aok-icon-functional-Equalizer; 
  }
}
.aok-icon-functional-Exit {
  &:before {
    content: $aok-icon-functional-Exit; 
  }
}
.aok-icon-functional-Expand-Arrow {
  &:before {
    content: $aok-icon-functional-Expand-Arrow; 
  }
}
.aok-icon-functional-Fax {
  &:before {
    content: $aok-icon-functional-Fax; 
  }
}
.aok-icon-functional-Feedback {
  &:before {
    content: $aok-icon-functional-Feedback; 
  }
}
.aok-icon-functional-Filter {
  &:before {
    content: $aok-icon-functional-Filter; 
  }
}
.aok-icon-functional-Flag {
  &:before {
    content: $aok-icon-functional-Flag; 
  }
}
.aok-icon-functional-Folder {
  &:before {
    content: $aok-icon-functional-Folder; 
  }
}
.aok-icon-functional-Formulare-Anfrage {
  &:before {
    content: $aok-icon-functional-Formulare-Anfrage; 
  }
}
.aok-icon-functional-Forward {
  &:before {
    content: $aok-icon-functional-Forward; 
  }
}
.aok-icon-functional-Gear-Settings {
  &:before {
    content: $aok-icon-functional-Gear-Settings; 
  }
}
.aok-icon-functional-Globe {
  &:before {
    content: $aok-icon-functional-Globe; 
  }
}
.aok-icon-functional-GooglePlus {
  &:before {
    content: $aok-icon-functional-GooglePlus; 
  }
}
.aok-icon-functional-Grid {
  &:before {
    content: $aok-icon-functional-Grid; 
  }
}
.aok-icon-functional-Headphones {
  &:before {
    content: $aok-icon-functional-Headphones; 
  }
}
.aok-icon-functional-Heart {
  &:before {
    content: $aok-icon-functional-Heart; 
  }
}
.aok-icon-functional-Heart-Light {
  &:before {
    content: $aok-icon-functional-Heart-Light; 
  }
}
.aok-icon-functional-Home {
  &:before {
    content: $aok-icon-functional-Home; 
  }
}
.aok-icon-functional-Image {
  &:before {
    content: $aok-icon-functional-Image; 
  }
}
.aok-icon-functional-Images {
  &:before {
    content: $aok-icon-functional-Images; 
  }
}
.aok-icon-functional-Info {
  &:before {
    content: $aok-icon-functional-Info; 
  }
}
.aok-icon-functional-Info-Circle-Filled {
  &:before {
    content: $aok-icon-functional-Info-Circle-Filled; 
  }
}
.aok-icon-functional-Keyword {
  &:before {
    content: $aok-icon-functional-Keyword; 
  }
}
.aok-icon-functional-Kundencenter {
  &:before {
    content: $aok-icon-functional-Kundencenter; 
  }
}
.aok-icon-functional-List {
  &:before {
    content: $aok-icon-functional-List; 
  }
}
.aok-icon-functional-Load {
  &:before {
    content: $aok-icon-functional-Load; 
  }
}
.aok-icon-functional-Location {
  &:before {
    content: $aok-icon-functional-Location; 
  }
}
.aok-icon-functional-Lock {
  &:before {
    content: $aok-icon-functional-Lock; 
  }
}
.aok-icon-functional-Login {
  &:before {
    content: $aok-icon-functional-Login; 
  }
}
.aok-icon-functional-Mail {
  &:before {
    content: $aok-icon-functional-Mail; 
  }
}
.aok-icon-functional-Mailbox {
  &:before {
    content: $aok-icon-functional-Mailbox; 
  }
}
.aok-icon-functional-Map {
  &:before {
    content: $aok-icon-functional-Map; 
  }
}
.aok-icon-functional-Menu {
  &:before {
    content: $aok-icon-functional-Menu; 
  }
}
.aok-icon-functional-Menu-Mobile {
  &:before {
    content: $aok-icon-functional-Menu-Mobile; 
  }
}
.aok-icon-functional-Mic {
  &:before {
    content: $aok-icon-functional-Mic; 
  }
}
.aok-icon-functional-Minus {
  &:before {
    content: $aok-icon-functional-Minus; 
  }
}
.aok-icon-functional-Mitglied-werden-Empfehlen {
  &:before {
    content: $aok-icon-functional-Mitglied-werden-Empfehlen; 
  }
}
.aok-icon-functional-New-Tab {
  &:before {
    content: $aok-icon-functional-New-Tab; 
  }
}
.aok-icon-functional-Office {
  &:before {
    content: $aok-icon-functional-Office; 
  }
}
.aok-icon-functional-Parking {
  &:before {
    content: $aok-icon-functional-Parking; 
  }
}
.aok-icon-functional-Pause {
  &:before {
    content: $aok-icon-functional-Pause; 
  }
}
.aok-icon-functional-Pause-Circle {
  &:before {
    content: $aok-icon-functional-Pause-Circle; 
  }
}
.aok-icon-functional-Pause-Circle-Filled {
  &:before {
    content: $aok-icon-functional-Pause-Circle-Filled; 
  }
}
.aok-icon-functional-PDF-file {
  &:before {
    content: $aok-icon-functional-PDF-file; 
  }
}
.aok-icon-functional-Pencil-Edit {
  &:before {
    content: $aok-icon-functional-Pencil-Edit; 
  }
}
.aok-icon-functional-Phone {
  &:before {
    content: $aok-icon-functional-Phone; 
  }
}
.aok-icon-functional-Play-Circle {
  &:before {
    content: $aok-icon-functional-Play-Circle; 
  }
}
.aok-icon-functional-Play-Circle-Filled {
  &:before {
    content: $aok-icon-functional-Play-Circle-Filled; 
  }
}
.aok-icon-functional-Play-Filled {
  &:before {
    content: $aok-icon-functional-Play-Filled; 
  }
}
.aok-icon-functional-Play-Outline {
  &:before {
    content: $aok-icon-functional-Play-Outline; 
  }
}
.aok-icon-functional-Plus {
  &:before {
    content: $aok-icon-functional-Plus; 
  }
}
.aok-icon-functional-Podcast {
  &:before {
    content: $aok-icon-functional-Podcast; 
  }
}
.aok-icon-functional-Power {
  &:before {
    content: $aok-icon-functional-Power; 
  }
}
.aok-icon-functional-Print {
  &:before {
    content: $aok-icon-functional-Print; 
  }
}
.aok-icon-functional-Proximity-Search {
  &:before {
    content: $aok-icon-functional-Proximity-Search; 
  }
}
.aok-icon-functional-Question-Circle {
  &:before {
    content: $aok-icon-functional-Question-Circle; 
  }
}
.aok-icon-functional-Questionmark-filled {
  &:before {
    content: $aok-icon-functional-Questionmark-filled; 
  }
}
.aok-icon-functional-Rueckrufservice {
  &:before {
    content: $aok-icon-functional-Rueckrufservice; 
  }
}
.aok-icon-functional-Screen-Share {
  &:before {
    content: $aok-icon-functional-Screen-Share; 
  }
}
.aok-icon-functional-Search {
  &:before {
    content: $aok-icon-functional-Search; 
  }
}
.aok-icon-functional-Share {
  &:before {
    content: $aok-icon-functional-Share; 
  }
}
.aok-icon-functional-Shrink {
  &:before {
    content: $aok-icon-functional-Shrink; 
  }
}
.aok-icon-functional-Sign {
  &:before {
    content: $aok-icon-functional-Sign; 
  }
}
.aok-icon-functional-Social-Facebook {
  &:before {
    content: $aok-icon-functional-Social-Facebook; 
  }
}
.aok-icon-functional-Social-Instagram {
  &:before {
    content: $aok-icon-functional-Social-Instagram; 
  }
}
.aok-icon-functional-Social-Kununu {
  &:before {
    content: $aok-icon-functional-Social-Kununu; 
  }
}
.aok-icon-functional-Social-LinkedIn {
  &:before {
    content: $aok-icon-functional-Social-LinkedIn; 
  }
}
.aok-icon-functional-Social-Twitter {
  &:before {
    content: $aok-icon-functional-Social-Twitter; 
  }
}
.aok-icon-functional-Social-Xing {
  &:before {
    content: $aok-icon-functional-Social-Xing; 
  }
}
.aok-icon-functional-Social-YouTube {
  &:before {
    content: $aok-icon-functional-Social-YouTube; 
  }
}
.aok-icon-functional-Sort {
  &:before {
    content: $aok-icon-functional-Sort; 
  }
}
.aok-icon-functional-Soundcloud {
  &:before {
    content: $aok-icon-functional-Soundcloud; 
  }
}
.aok-icon-functional-Speech-Bubbble {
  &:before {
    content: $aok-icon-functional-Speech-Bubbble; 
  }
}
.aok-icon-functional-Spotify {
  &:before {
    content: $aok-icon-functional-Spotify; 
  }
}
.aok-icon-functional-Sprache-Gebaerde {
  &:before {
    content: $aok-icon-functional-Sprache-Gebaerde; 
  }
}
.aok-icon-functional-Sprache-Leicht {
  &:before {
    content: $aok-icon-functional-Sprache-Leicht; 
  }
}
.aok-icon-functional-Star-Filled {
  &:before {
    content: $aok-icon-functional-Star-Filled; 
  }
}
.aok-icon-functional-Synonym {
  &:before {
    content: $aok-icon-functional-Synonym; 
  }
}
.aok-icon-functional-Target {
  &:before {
    content: $aok-icon-functional-Target; 
  }
}
.aok-icon-functional-Top {
  &:before {
    content: $aok-icon-functional-Top; 
  }
}
.aok-icon-functional-Train {
  &:before {
    content: $aok-icon-functional-Train; 
  }
}
.aok-icon-functional-Unlock {
  &:before {
    content: $aok-icon-functional-Unlock; 
  }
}
.aok-icon-functional-Video-Camera {
  &:before {
    content: $aok-icon-functional-Video-Camera; 
  }
}
.aok-icon-functional-Video-Chat {
  &:before {
    content: $aok-icon-functional-Video-Chat; 
  }
}
.aok-icon-functional-Virus {
  &:before {
    content: $aok-icon-functional-Virus; 
  }
}
.aok-icon-functional-Volume_decrease {
  &:before {
    content: $aok-icon-functional-Volume_decrease; 
  }
}
.aok-icon-functional-Volume_increase {
  &:before {
    content: $aok-icon-functional-Volume_increase; 
  }
}
.aok-icon-functional-Volume_medium {
  &:before {
    content: $aok-icon-functional-Volume_medium; 
  }
}
.aok-icon-functional-Volume_mute {
  &:before {
    content: $aok-icon-functional-Volume_mute; 
  }
}
.aok-icon-functional-WhatsApp {
  &:before {
    content: $aok-icon-functional-WhatsApp; 
  }
}
.aok-icon-functional-Windows {
  &:before {
    content: $aok-icon-functional-Windows; 
  }
}
.aok-icon-functional-Zoom_in {
  &:before {
    content: $aok-icon-functional-Zoom_in; 
  }
}
.aok-icon-functional-Zoom_out {
  &:before {
    content: $aok-icon-functional-Zoom_out; 
  }
}
.aok-icon-functional-Zurueck-Vor {
  &:before {
    content: $aok-icon-functional-Zurueck-Vor; 
  }
}

