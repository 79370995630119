@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "mixins";
html,
body,
.page-wrapper {
    height: 100%;
}


/*.page-wrapper {
  top: 0;
  right:0px;
  position: relative;
  @include transition(all .3s ease-out);
}*/

.page-wrapper {
    display: flex;
    flex-direction: column;
    &.page-wrapper--acvite-mobile-nav {
        position: absolute;
        top: 0;
        right: 270px;
        position: relative;
        &::after {
            opacity: 0.5;
        }
        .header-navigation {
            right: 0;
            overflow-y: auto;
            background-color: #fff;
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: $zindex-modal-backdrop;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0;
        transition: opacity 0.4s;
        pointer-events: none;
    }
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    padding-left: 0;
    padding-right: 0;
    &.content-wrapper {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.mobile-action-bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: $white;
    .mobile-action-bar_item {
        display: flex;
        flex: 1 0 50%;
        .btn {
            width: 100%;
            min-width: auto;
            white-space: nowrap;
        }
        // .btn-secondary_default{
        //   background: #fff;
        // }
    }
}

.main {
    flex: 1 0 auto;
    &.landing {
        height: calc(100% - 50px);
        @include media-breakpoint-up(lg) {
            height: calc(100% - 93px);
        }
    }
}

.mobile-menu-open {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: $zindex-modal-backdrop;
    cursor: pointer;
}