@import "variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

.inner-addon {
  position: relative;
}

.inner-addon i {
  position: absolute;
  padding: 10px;
  pointer-events: none;

  height: 100%;
  align-items: center;
  display: flex;
}

.right-addon i {
  right: 0px;
}

.right-addon input {
  padding-right: 30px;
}

.datepicker {
  border-radius: 0;
  &.datepicker-dropdown {
    font-size: 14px;
  }
  &.dropdown-menu {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active:active {
  color: #fff;
  background-color: #3da562;
  border-color: #3da562;
  border-radius: 0;
}

.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active.focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active:active:hover,
.datepicker table tr td.today.active.focus,
.datepicker table tr td.today.active:focus,
.datepicker table tr td.today.active:hover,
.datepicker table tr td.today:active.focus,
.datepicker table tr td.today:active:focus,
.datepicker table tr td.today:active:hover {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.datepicker table tr td span {
  border-radius: 0;
}
.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev,
.datepicker tfoot tr th {
  border-radius: 0;
}
.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  border-radius: 0;
}

.datepicker table tr td.today {
  color: #fff;
  background-color: #ff9900;
  border-color: #ff9900;
  border-radius: 0;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today.focus,
.datepicker table tr td.today:focus {
  color: #fff;
  background-color: #f29100;
  border-color: #b26b00;
}

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover:active {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}
