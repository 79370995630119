@import "bootstrap_overrides/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "icons_functional_variables";

.table {
  display: table;
  border-collapse: separate;
  font-weight: 400;
  border-spacing: 4px;

  .theader {
    display: table-row;

    .table_header {
      display: table-cell;
      background: #e4e5e5;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 700;
    }
  }

  .table_row {
    display: table-row;

    .table_small {
      display: table-cell;
      background-color: #f6f6f6;

      .table_cell {
        background-color: #f6f6f6;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.table_row > .table_small > .table_cell:nth-child(odd) {
  display: none;
  font-weight: 700;
  color: #303030;
  background: #e4e5e5;
}

.table_row > .table_small > .table_cell {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #5b5b5b;
}

@media screen and (max-width: 900px) {
}

@media screen and (max-width: 650px) {
  .table_row {
    display: block !important;
  }
  .theader {
    display: none !important;
  }

  .table_small {
    display: inline-block !important;
    width: 100%;
    margin-top: -6px;
  }

  .table_row > .table_small > .table_cell {
    display: inline-block !important;
    width: 100%;
  }
}
